import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TimelineFullscreenModalComponent } from 'src/app/app-estimate/components/timeline-fullscreen-modal/timeline-fullscreen-modal.component';
import { IEstimateTimeline } from 'src/app/app-estimate/shared/models/estimate.model';
import { ModalService } from '../../services/modal.service';
import { FsTimelineFullscreenModalComponent } from '../fs-timeline-fullscreen-modal/fs-timeline-fullscreen-modal.component';

@Component({
  selector: 'app-fs-project-timeline',
  templateUrl: './fs-project-timeline.component.html',
  styleUrls: ['./fs-project-timeline.component.scss']
})
export class FsProjectTimelineComponent implements OnInit, OnChanges {

  @ViewChild('timelineContainer') timelineContainer: ElementRef;
  @ViewChild('sectionContainer') sectionContainer: ElementRef;

  @Input() timelines: IEstimateTimeline[] = [];
  @Input() allowFullScreen: boolean = true;

  canScroll: boolean;
  canScrollNext: boolean;
  canScrollPrev: boolean;

  sprintsCount: number;
  sprints: any[];

  constructor(
    private modalService: ModalService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.timelines?.currentValue?.length != changes?.timelines?.previousValue?.length ||
      JSON.stringify(changes?.timelines?.currentValue) != JSON.stringify(changes?.timelines?.previousValue)) {
      this.detectChanges();
    }
  }

  ngOnInit(): void {
    setTimeout(() => { this.maintainScrolls(); }, 1000);

    setTimeout(() => {
      this.timelineContainer.nativeElement.addEventListener('scroll', () => {
        this.maintainScrolls();
      });
    }, 100);
  }

  serviceMargin(service: IEstimateTimeline) {
    const sprintWidth = `calc((100% / ${this.sprints.length}) * ${service.sprintStart - 1})`
    return sprintWidth;
  }

  serviceWidth(service: IEstimateTimeline) {
    const sprintWidth = `calc((100% / ${this.sprints.length}) * ${service.sprintEnd - service.sprintStart + 1})`
    return sprintWidth;
  }

  getSprintWidth() {
    let xx = window.innerWidth < 400 ? 'calc(100vw - 15%)' : '110px';
    return `max(calc(100% / ${this.sprintsCount}), ${xx})`;
  }

  getContainerWidth() {
    return `calc(${this.getSprintWidth()} * ${this.sprintsCount})`
  }

  scrollBack() {
    let scrollSize = this.timelineContainer.nativeElement.offsetWidth;
    if (this.canScrollPrev) {
      this.sideScroll(this.timelineContainer.nativeElement, 'left', 5, scrollSize, 10);
    }
  }

  scrollNext() {
    let scrollSize = this.timelineContainer.nativeElement.offsetWidth;
    if (this.canScrollNext) {
      this.sideScroll(this.timelineContainer.nativeElement, 'right', 5, scrollSize, 10);
    }
  }

  seeInFullSchreen() {
    this.modalService.showModal(import('../fs-timeline-fullscreen-modal/fs-timeline-fullscreen-modal.component'))
      .subscribe((instance: FsTimelineFullscreenModalComponent) => {
        instance.timelines = this.timelines;
      })
  }

  private detectChanges() {

    this.sprintsCount = this.timelines && this.timelines.length > 0 ? Math.max(...this.timelines?.map(x => x.sprintEnd)) : 0;
    this.sprints = [...Array(this.sprintsCount).keys()].map(x => {
      return {
        name: x == 0 ? 'Consultation' : `Sprint ${x}`
      }
    });
    this.maintainScrolls();
  }

  private maintainScrolls() {
    setTimeout(() => {
      this.canScroll = this.timelineContainer?.nativeElement?.scrollWidth != this.timelineContainer?.nativeElement?.clientWidth;
      this.canScrollNext = this.timelineContainer?.nativeElement?.scrollWidth - this.timelineContainer?.nativeElement?.clientWidth > this.timelineContainer?.nativeElement?.scrollLeft;
      this.canScrollPrev = this.timelineContainer?.nativeElement?.scrollLeft > 0;
    }, 100);
  }

  private sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;

    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
}
