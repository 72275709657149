<!-- <section>
    <div class="container">
        <div class="introduction-layout">

            <div class="inner-layout">
                <h1 [innerHtml]="content.introduction.title"></h1>
                <p [innerHtml]="content.introduction.subTitle"></p>
            </div>

            <div class="button-container" [routerLink]="content.introduction.cta.link">
                <button>
                    {{ content.introduction.cta.title }}
                </button>
            </div>
        </div>
    </div>
</section> -->


<section class="hero-section" [style.height]="sectionHeight">
    <div class="container heading">

        <app-fs-dashed-header [backgroundColor]="'#000000'" [dashColor]="'#202020'">
            <div class="container heading">
                <div class="services-header-layout">

                    <div class="header-item-content background">
                        <img src="https://res.cloudinary.com/faststartup/image/upload/v1661144986/landing/faststartup-hero-1_qbl0ow.png" alt="">
                    </div>

                    <div class="header-item-content">

                        <div class="container inner-content">
                            <h1 [innerHtml]="content.introduction.title"></h1>
                            <p class="hero-description" [innerHtml]="content.introduction.subTitle"></p>
                        </div>

                        <div class="button-container">
                            <button (click)="navigateTo(content.introduction.cta.link)">
                                {{ content.introduction.cta.title }}
                            </button>
                        </div>

                    </div>



                </div>
            </div>
        </app-fs-dashed-header>

    </div>
</section>