import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './app-main/pages/main-page/main-page.component';
import { SiteLayoutComponent } from './core/layouts/site-layout/site-layout.component';

const childRoutes: Routes = [
  { path: '', component: MainPageComponent },
  { path: 'joinus', loadChildren: () => import('./app-joinus/app-joinus.module').then(m => m.AppJoinusModule) },
  { path: 'case-study', loadChildren: () => import('./app-case-study/app-case-study.module').then(m => m.AppCaseStudyModule) },
  { path: 'contact', loadChildren: () => import('./app-contact/app-contact.module').then(m => m.AppContactModule) },
  //{ path: 'services', loadChildren: () => import('./app-flow/app-flow.module').then(m => m.AppFlowModule) },
  { path: 'testimonials', loadChildren: () => import('./app-testimonial/app-testimonial.module').then(m => m.AppTestimonialModule) },
  { path: 'blog', loadChildren: () => import('./app-blog/app-blog.module').then(m => m.AppBlogModule) },
  { path: 'services', loadChildren: () => import('./app-services/app-services.module').then(m => m.AppServicesModule) },
  //{ path: 'freeInitialConsultationScheduled', loadChildren: () => import('./app-consultation/app-consultation.module').then(m => m.AppConsultationModule) },
  { path: 'lets-talk', loadChildren: () => import('./app-talk/app-talk.module').then(m => m.AppTalkModule) },
  { path: 'estimate', loadChildren: () => import('./app-estimate/app-estimate.module').then(m => m.AppEstimateModule) },
  { path: 'build-now', loadChildren: () => import('./app-estimate/app-estimate.module').then(m => m.AppEstimateModule) },
  { path: 'team', loadChildren: () => import('./app-team/app-team.module').then(m => m.AppTeamModule) },
  { path: 'work', loadChildren: () => import('./app-work/app-work.module').then(m => m.AppWorkModule) },
  { path: 'privacy', loadChildren: () => import('./app-privacy/app-privacy.module').then(m => m.AppPrivacyModule) },
  { path: 'terms', loadChildren: () => import('./app-terms/app-terms.module').then(m => m.AppTermsModule) },
  { path: '**', component: MainPageComponent }
];

const routes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: childRoutes
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: "reload",
    scrollOffset: [0, 0],
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
