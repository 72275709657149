import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SessionService } from './shared/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'faststartup-landing-web';

  @HostListener('window:resize', ['$event']) onResize(event) { this.maintainPageScroll(); }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService) {
  }

  ngOnInit(): void {
    document.body.className = '';

    // this.maintainSession();
    this.maintainPageScroll();

    this.router.events.subscribe(() => {
      this.maintainPageScroll();
    });
  }

  private maintainPageScroll() {
    if (this.router.url.endsWith('/build-now') && window.innerWidth > 900) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

  private maintainSession() {
    const uid = localStorage.getItem('uid') || this.getQueryParameterByName('uid');
    if (uid) {
      history.replaceState(null, null, window.location.pathname);
      this.sessionService.renew(uid)
        .subscribe((resp) => {
          localStorage.setItem('uid', resp);
        })
    } else {
      const path = window.location.pathname;
      if (path == '/') {
        window.location.href = 'https://meet.faststartup.io';
      }
    }
  }

  private getQueryParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
