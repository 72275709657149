import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-fs-timeline-fullscreen-modal',
  templateUrl: './fs-timeline-fullscreen-modal.component.html',
  styleUrls: ['./fs-timeline-fullscreen-modal.component.scss']
})
export class FsTimelineFullscreenModalComponent implements OnInit {


  @ViewChild('sectionContainer') sectionContainer: ElementRef;

  @HostListener('window:orientationchange', ['$event']) onResize(event) { this.fixOrientation(); }

  timelines: any;

  containerWidth: string;
  containerHeight: string;
  transformOrigin: number = window.innerWidth / 2;
  needRotate: boolean = false;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.fixOrientation();
  }

  exitFullScreen() {
    this.modalService.hideModal();
  }

  private fixOrientation() {
    setTimeout(() => {
      // this.containerWidth = this.sectionContainer.nativeElement.offsetHeight - 20;
      // this.containerHeight = this.sectionContainer.nativeElement.offsetWidth - 20;

      const windowWidth = this.sectionContainer.nativeElement.offsetWidth;
      const windowHeight = this.sectionContainer.nativeElement.offsetHeight;
      this.transformOrigin = window.innerWidth / 2;

      if (windowWidth > windowHeight) {
        //Landscape
        this.containerWidth = '';
        this.containerHeight = '';
        this.needRotate = false;
      } else {
        //Portrait
        this.containerWidth = `${windowHeight}px`;
        this.containerHeight = `${windowWidth}px`;
        this.needRotate = true;
      }

    }, 100);
  }
}
