import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-ticker-row',
  templateUrl: './fs-ticker-row.component.html',
  styleUrls: ['./fs-ticker-row.component.scss']
})
export class FsTickerRowComponent implements OnInit {

  @Input('tickers') tickers: any[] = [];
  @Input() isReverse: boolean = false;
  @Input() animationDuration = 120;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.setCssValue('--animation-duration', `${this.animationDuration}s`);
  }

  private setCssValue(variableName, value) {
    this.elementRef.nativeElement.style
      .setProperty(variableName, value);
  }
}
