<section *ngIf="faqs">
    <div class="container">
        <div class="inner-container">
            <h4>{{ content.title }}</h4>
            <div class="faq-items">
                <div class="faq-item" *ngFor="let faq of faqs; index as i;" [ngClass]="{'open':faq.isOpen}">
                    <div class="faq-question" (click)="toggleFaqItem(faq, i)">
                        <label>{{ faq.question }}</label>
                        <button></button>
                    </div>
                    <div class="faq-answer" [style.height]="faq.bodyHeight">
                        <p #anserwPanel>
                            {{ faq.answer }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>