import { HttpErrorResponse } from "@angular/common/http";
import { AbstractControl } from "@angular/forms";
import { throwError } from "rxjs";

export function copyToClipboard(msg: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}

export function handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
}

export function elementIsVisibleInViewport(el) {
    if (!el) { return false; }

    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
    );
}

export function elementIsFullyVisibleInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
    );
}

export function getCssValue(el, variableName) {
    return getComputedStyle(el).getPropertyValue(variableName);
}

export function setCssValue(el, variableName, value) {
    el.style.setProperty(variableName, value);
}

export function setMessage(name: string, c: AbstractControl) {
    let errorMessage = '';
    if (/*(c.touched || c.dirty) &&*/ c.errors) {
        errorMessage = Object.keys(c.errors).map(k => `${name}_${k}`).join();
    } return errorMessage;
}

export const objectsAreEqual = (object1, object2?) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !objectsAreEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }
    return true;
}

export const isObject = (object) => {
    return object != null && typeof object === 'object';
}

export function elementBelongsToTarget(target: any, content: any) {
    let x = true;
    let a = target as any;
    let els1 = [];

    if (target == content) { x = false; }

    while (a && x) {
        if (a == content) { x = false; }
        els1.unshift(a);
        a = a.parentNode;
    }

    return x;
}