import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createPageContent } from 'src/content/main.content';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {

  content: any;
  sectionHeight: string;

  @HostListener('window:orientationchange', ['$event']) onResize(event) { this.maintainResponsiveHeight(); }

  constructor(
    private elementRef: ElementRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.content = createPageContent();
    this.maintainResponsiveHeight();
  }

  navigateTo(link: any) {
    this.router.navigate(link);
  }

  private maintainResponsiveHeight() {
    setTimeout(() => {
      if (window.innerWidth <= 900) {
        this.sectionHeight = `${Math.max(window.innerHeight - 60, 500)}px`;
      } else {
        this.sectionHeight = '';
      }

      console.log(this.sectionHeight);
    }, 100);
  }
}
