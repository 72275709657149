import { Component, OnInit } from '@angular/core';
import { createPageContent } from 'src/content/main.content';

@Component({
  selector: 'app-trusted-by',
  templateUrl: './trusted-by.component.html',
  styleUrls: ['./trusted-by.component.scss']
})
export class TrustedByComponent implements OnInit {

  content: any;

  constructor() { }

  ngOnInit(): void {
    this.content = createPageContent().trustedBy;
  }

}
