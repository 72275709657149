import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { BodyComponent } from './components/body/body.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './components/modal/modal.component';
import { LazyImgDirective } from './directives/lazy-img.directive';
import { FadeComponent } from './components/fade/fade.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    SiteLayoutComponent,
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
    LazyImgDirective,
    FadeComponent,
    HeaderMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
  ],
  exports: [
    BodyComponent
  ]
})
export class CoreModule { }
