<section #modalSection [style.height]="sectionHeight + 'px'" class="modal-root" [ngClass]="{'scrolled' : modalIsScrolled}" [hidden]="!modalIsShown">
    <div class="modal-layout">
        <div class="container c-container" #modalContainer>
            <ng-template #modalContent></ng-template>
        </div>
    </div>
</section>

<!-- <div class="pricing-member-modal" *ngIf="isVisible">
    <div class="modal-panel">
        <div class="pricing-member-layout">
            <div class="header">
                <button (click)="closeModal()">
                    <img src="../../../../assets/icon-close-bold.svg" alt="">
                </button>
            </div>
            <div class="body">
                <b>
                    <h3>{{ title }}</h3>
                </b>
                <p *ngFor="let content of contents">
                    {{ content }}
                </p>
            </div>
        </div>
        <br>
    </div>
</div> -->