<section>
    <div class="img-panel">
        <img [src]="blog.img" alt="">
    </div>
    <div class="details">
        <h4>{{ blog.title }}</h4>
        <p>
            {{ blog.createDate | date: 'MMMM d, y' }} {{ blog.location }}
        </p>
    </div>
</section>