import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createPageContent } from 'src/content/footer.content';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  content: any;
  displayIsAllowed: boolean = true;

  private displayNotAllowedPages = ['/estimate', '/build-now'];
  //private displayNotAllowedPages = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.content = createPageContent();
    this.maintainVisibility();

    this.router.events.subscribe(() => { this.maintainVisibility(); });
  }

  private maintainVisibility() {
    this.displayIsAllowed = this.displayNotAllowedPages.filter(x => this.router.url.indexOf(x) != -1).length == 0;
  }
}
