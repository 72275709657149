import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createPageContent } from 'src/content/faq.content';

@Component({
  selector: 'app-fs-faq',
  templateUrl: './fs-faq.component.html',
  styleUrls: ['./fs-faq.component.scss']
})
export class FsFaqComponent implements OnInit {

  content: any;
  isOpen: boolean;
  faqBodyHeight: string;

  @Input() faqs: any[];

  @ViewChildren("anserwPanel") anserwPanels: QueryList<ElementRef>;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.content = createPageContent();
  }

  toggleFaqItem(faq, index) {
    faq.isOpen = !faq.isOpen;
    faq.bodyHeight = `${faq.isOpen ? this.anserwPanels.find((_, i) => i == index).nativeElement.offsetHeight + 40 : 0}px`;
  }
}
