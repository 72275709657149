import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fs-skew-button',
  templateUrl: './fs-skew-button.component.html',
  styleUrls: ['./fs-skew-button.component.scss']
})
export class FsSkewButtonComponent implements OnInit {

  loaded = false;

  @Input('skewLeft') skewLeft: boolean;
  @Input('skewTop') skewTop: boolean;
  @Input('skewBoth') skewBoth: boolean;

  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('button') button: ElementRef;
  @Input('disabled') disabled: boolean;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.initPadding()
  }

  btnClick(e) {
    if (!this.disabled) { this.onClick.emit(e); }
  }

  private initPadding() {
    setTimeout(() => {
      let skew = parseFloat(this.getCssValue('--fs-skew'));
      let b = this.button.nativeElement.offsetHeight;
      let c = b / (Math.sin((90 - skew) * Math.PI / 180));

      let a_square = (c * c) - (b * b);
      if (a_square < 0) { a_square = a_square * -1; }
      this.setCssValue('--fs-skew-padding', `${Math.sqrt(a_square)}px`);

      this.loaded = true;
    }, 5);
  }

  private getCssValue(variableName) {
    return getComputedStyle(this.elementRef.nativeElement)
      .getPropertyValue(variableName);
  }

  private setCssValue(variableName, value) {
    this.elementRef.nativeElement.style
      .setProperty(variableName, value);
  }
}
