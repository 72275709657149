import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription, ReplaySubject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { elementBelongsToTarget } from '../../utils/utils';
import { ISelect } from './select.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit {

  @ViewChild('select') select!: ElementRef;
  @ViewChild('selectContent') selectContent!: ElementRef;

  @Input() items!: ISelect[];
  @Input() color: string = '#101828';
  @Input() backgroundColor: string = '#FCEA10';
  @Input() borderColor: string = '#FCEA10';
  @Input() arrowColor: string = '#667085';
  @Input() label: string = 'SELECT';

  @Input() noBorder!: boolean;
  @Input() contentWidth!: number;

  @Output() onSelect: EventEmitter<number | string> = new EventEmitter();

  private $click!: Subscription;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  private _value: number | string = '';

  opened: boolean = false;
  bodyHeight: number = 0;


  get selectName() {
    return this.selectedItem ?? { value: this.label };
  }

  get selectedItem() {
    return this.items?.filter(x => x.id == this.value)[0];
  }

  constructor() { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  toggle() {
    if (this.items && this.items?.length > 0) {
      this.opened = !this.opened;
      if (this.opened) { this.open(); }
      else { this.close(); }
    }
  }

  selectItem(item: ISelect) {
    this.value = item.id;
    this.close();
    this.onSelect.emit(item.id);
  }

  private open() {
    this.opened = true;
    this.bodyHeight = this.selectContent.nativeElement.offsetHeight;
    this.registerClickEvent();
  }

  private close() {
    this.bodyHeight = 0;
    this.$click?.unsubscribe();
    setTimeout(() => { this.opened = false; }, 300);
  }

  private registerClickEvent() {
    this.$click = fromEvent(window, 'click')
      .pipe(takeUntil(this.$destroyed))
      .subscribe((e: any) => {
        if (elementBelongsToTarget(e.target, this.select?.nativeElement)) {
          this.close();
        }
      })
  }
}
