export function createPageContent() {
  return {
    logo: {
      url: '/',
      img: '../../../../assets/logo.svg',
    },

    navigation: {
      columns: [
        {
          links: [
            { link: ['/work'], name: 'Work' },
            { link: ['/testimonials'], name: 'Testimonials' },
            { link: ['/services'], name: 'Services' },
            { link: ['/team'], name: 'Team' },
            {
              link: ['/joinus'],
              name: 'Joinus',
              toolTip: { name: 'Hiring', link: ['/joinus'] },
            },
            { link: ['/blog'], name: 'Blog' },
          ],
        },

        {
            title: 'Work',
            links: [
              { link: ['/work/video-streaming'], name: 'Live / Video Streaming' },
              { link: ['/work/marketplace'], name: 'Marketplace' },
              { link: ['/work/on-demand'], name: 'On Demand' },
              { link: ['/work/accounting'], name: 'CMS / Admin Dashboard' },
              { link: ['/work/saas'], name: 'SaaS' },
              { link: ['/work/social'], name: 'Social' },
              { link: ['/work/social'], name: 'Travel' },
            ],
          },

        {
          title: 'Compare',
          links: [
            { link: [''], name: 'Toptal Alternative' },
            { link: [''], name: 'Upwork Alternative' },
            { link: [''], name: 'Freelancer Alternative' },
            { link: [''], name: 'Guru.com Alternative' },
            { link: [''], name: 'Dev Firm Alternative' },
            { link: [''], name: 'Creative Agency Alternative' },
            { link: [''], name: 'Developer Alternative' },
          ],
        },

        {
          title: 'Consultation',
          links: [
            { link: ['/services/discovery-call'], name: 'Discovery Call' },
            { link: ['/services/requirement-gathering'], name: 'Requirement Gathering' },
            { link: ['/services/go-to-market-strategy'], name: 'Go-To-Market Strategy' },
            { link: ['/services/mindmap'], name: 'Mindmap' },
            { link: ['/services/user-persona'], name: 'User Persona' },
            { link: ['/services/user-journey'], name: 'User Journey' },
            { link: ['/services/user-story'], name: 'User Story' },
          ],
        },

        {
          title: 'Design',
          links: [
            { link: ['/services/research-and-planning'], name: 'Research & Planning' },
            { link: ['/services/visual-identity'], name: 'Visual Identity' },
            { link: ['/services/product-design'], name: 'Product Design' },
            { link: ['/services/design-implementation'], name: 'Design Implementation' },
            { link: ['/services/wireframe'], name: 'Wireframe' },
            { link: ['/services/high-fidelity-ui'], name: 'High Fidelity UI' },
            { link: ['/services/ux-flow'], name: 'UX Flow' },
          ],
        },

        {
          title: 'Engineering',
          links: [
            { link: ['/services/initialization'], name: 'Initialization' },
            { link: ['/services/testing'], name: 'Testing' },
            { link: ['/services/frontend'], name: 'Frontend' },
            { link: ['/services/backend-and-devops'], name: 'Backend & DevOps' },
            { link: ['/services/marketing-and-landing'], name: 'Marketing & Landing' },
            { link: ['/services/web-app'], name: 'Web App' },
            { link: ['/services/mobile-ios-android'], name: 'Mobile iOS/Android' },
          ],
        },
      ],
    },

    legal: {
      title: 'FastStartup - Made in California',
      links: [
        { link: ['/privacy'], name: 'Privacy Policy' },
        { link: ['/terms'], name: 'Terms of Service' },
      ]
    },
  };
}