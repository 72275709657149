import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-fs-circular-build-button',
  templateUrl: './fs-circular-build-button.component.html',
  styleUrls: ['./fs-circular-build-button.component.scss']
})
export class FsCircularBuildButtonComponent implements OnInit {

  isRunning = false;
  isInit = false;
  displayIsAllowed = true;

  private displayNotAllowedPages = ['/team', '/joinus', '/estimate', '/build-now'];

  constructor(
    private router: Router
  ) {
    setTimeout(() => { this.isInit = true; }, 100);

    this.router.events.subscribe(() => {
      this.isRunning = false;
      this.displayIsAllowed = this.displayNotAllowedPages.filter(x => this.router.url.indexOf(x) != -1).length == 0;
    });
  }

  ngOnInit(): void {
    this.isRunning = false;
  }

  buildClick() {
    this.isRunning = true;
    setTimeout(() => {
      // window.location.href = 'http://portal.faststartup.io/login?step=signUp';
      const w = window as any;
      const message = `Hello,
I am interested in hiring FastStartup for my project. Are you available for a quick chat?`;
      w.Intercom('showNewMessage', message);
    }, 0);

    setTimeout(() => { this.isRunning = false; }, 500);
  }
}
