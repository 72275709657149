import { Component, Input, OnInit } from '@angular/core';
import { IEstimateTimeline } from 'src/app/app-estimate/shared/models/estimate.model';

@Component({
  selector: 'app-fs-estimated-budget',
  templateUrl: './fs-estimated-budget.component.html',
  styleUrls: ['./fs-estimated-budget.component.scss']
})
export class FsEstimatedBudgetComponent implements OnInit {

  @Input() services: IEstimateTimeline[];

  get totalPrice() {
    return this.services?.map(x => x.price * (x.sprintEnd - x.sprintStart + 1)).reduce((a, b) => a + b, 0);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
