<section #sectionContainer>
    <div class="full-timeline-container" [ngClass]="{'rotate' : needRotate}" [style.width]="containerWidth"
        [style.height]="containerHeight" [style.transform-origin]="transformOrigin + 'px'">
        <app-fs-project-timeline [allowFullScreen]="false" [timelines]="timelines"></app-fs-project-timeline>
        <div class="exit-full-screen-button-container" [ngClass]="{'rotated' : needRotate}">
            <button (click)="exitFullScreen()">
                Exit Full Screen
            </button>
        </div>
    </div>

    <!-- <app-project-timeline [allowFullScreen]="false" [timelines]="timelines"></app-project-timeline>

    -->
</section>