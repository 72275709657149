import { Component, OnInit } from '@angular/core';
import { createPageContent } from 'src/content/main.content';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent implements OnInit {

  content: any;

  constructor() { }

  ngOnInit(): void {
    this.content = createPageContent().trustedBy;
  }

}
