<div class="button-container" [ngClass]="{'init' : isInit}" *ngIf="displayIsAllowed">
    <button class="btn-build" [ngClass]="{'running' : isRunning}" (click)="buildClick()">
        <div class="content">
            <div class="rotating speed-one">
                <div class="rotating speed-two">
                    <div class="rotating speed-three">
                        <img class="build-text" src="../../../../assets/car.svg" alt="">
                    </div>
                </div>
            </div>
            <img class="racing-car" src="../../../../assets/btn-build-car.svg" alt="">
        </div>
    </button>
</div>