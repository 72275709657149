export function createPageContent() {
    return {
        locationDetails: {
            title: 'Meet us',
            description: 'And we brew our software with the best coffee!',
            locationImage: 'https://res.cloudinary.com/faststartup/image/upload/v1708669277/landing/faststartup-office-locations.png',
            addressDetails: '',
            btnBack: {
                img: '../../../../assets/arrow.svg',
            }
        },
        contactDetails: {
            formOne: {
                title: `Let's talk`,
                description: 'Request a FREE quote for your project.',
                mailTo: {
                    label: 'success@faststartup.io',
                    link: 'success@faststartup.io'
                },
                tel: {
                    label: '+1 (415) 787-7676',
                    link: '+14157877676'
                },
                inquiry: {
                    title: `LET'S TALK ABOUT`,
                    reasons: [
                        { name: 'reason', label: 'Your great project', value: '1' },
                        { name: 'reason', label: 'Meeting for a coffee', value: '2' },
                        { name: 'reason', label: 'Setting up remote workspace', value: '3' },
                        { name: 'reason', label: 'Game of Thrones', value: '4' },
                    ],
                    button: {
                        label: 'next'
                    }
                }
            },
            formTwo: {
                title: 'Let’s craft your product.',
                timeline: {
                    title: 'Timeline',
                    items: [
                        { name: 'timeline', label: '1 month', value: '1 month' },
                        { name: 'timeline', label: '2-3 month', value: '2-3 months' },
                        { name: 'timeline', label: '4-8 month', value: '4-8 months' },
                        { name: 'timeline', label: '9+ month', value: '9+ months' }
                    ]
                },
                projectType: {
                    title: 'Project Type',
                    items: [
                        { name: 'projectType', label: 'Web', value: 'web' },
                        { name: 'projectType', label: 'Mobile', value: 'mobile' },
                        { name: 'projectType', label: 'Desktop', value: 'desktop' },
                        { name: 'projectType', label: 'Enterprise Platforms', value: 'enterpriseplatforms' },
                        { name: 'projectType', label: 'DevOps', value: 'devops' },
                        { name: 'projectType', label: 'Business Intelligence', value: 'businessintelligence' },
                        { name: 'projectType', label: 'Other', value: 'other' },
                    ]
                },
                personalDetails: {
                    title: 'Personal details',
                    clientName: { placeholder: 'Your Name' },
                    companyName: { placeholder: 'Company Name' },
                    businessEmail: { placeholder: 'Business Name' },
                    budget: { placeholder: 'Budget' },
                    projectDescription: { placeholder: 'Tell us more about your project' },
                    button: {
                        label: 'Request a quote'
                    }
                }
            }
        }
    }
}