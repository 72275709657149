<section>
    <p class="price-summary"> <span>from</span> ${{ totalPrice | number: '1.0-0' }} </p>

    <div class="price-table">

        <div class="price-header">
            <label>Type</label>
            <label class="unit-price">Unit Price</label>
            <label>Sprints</label>
            <label>Budget</label>

            <ng-container *ngFor="let service of services">
                <p>{{ service.name }} <span class="responsive-price"><br>${{ service.price | number: '1.0-0'}} per Sprint</span> </p>
                <p class="unit-price">${{ service.price | number: '1.0-0'}}</p>
                <p>{{ service.sprintEnd - service.sprintStart + 1 }}</p>
                <p>${{ service.price * (service.sprintEnd - service.sprintStart + 1) | number: '1.0-0' }}</p>
            </ng-container>
        </div>
    </div>
</section>