<div #dropDownContainer class="drop-down-menu-container" [ngClass]="{'open' : isOpen}">
    <div class="drop-down-menu-selector" [ngClass]="{'shadowed':isOpen}" (click)="changeDropdown($event)">
        <label #labelText>
            {{ selectorLabel }}
        </label>
        <i></i>
    </div>
    <div class="drop-down-menu-selector fake" [ngClass]="{'visible':isOpen}" (click)="changeDropdown($event)">
        <label>
            <span *ngIf="!isOverflown">{{ selectorLabel }}</span>
            <span *ngIf="isOverflown">{{ longSelectorLabel }}</span>
        </label>
        <i></i>
    </div>

    <div class="drop-down-menu-body-container" [ngClass]="{'opened' : isOpen, 'scrolled' : dropDownIsScrolled}"
        [style.height]="dropDownHeight">
        <div class="drop-down-menu-layout">
            <div class="drop-down-menu-content" #dropDownContent>
                <button class="btn-close" (click)="changeDropdown($event)">
                    <img src="../../../../assets/img-close.png" alt="">
                </button>
                <ng-content></ng-content>
                <button class="btn-done" (click)="changeDropdown($event)">
                    Done
                </button>
            </div>
        </div>
    </div>
</div>