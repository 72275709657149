import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fs-range',
  templateUrl: './fs-range.component.html',
  styleUrls: ['./fs-range.component.scss']
})
export class FsRangeComponent implements OnInit, OnChanges {
  @ViewChild("rangeContainer") rangeContainer: ElementRef;
  @Input('value') value: number = 2;
  @Input('max') max: number = 8;
  @Input('min') min: number = 1;

  @Output('onRangeChange') onRangeChange: EventEmitter<number> = new EventEmitter<number>();

  isVisible: boolean;

  bulletPosition: string;
  bulletValue: string;

  containerWidth: number;
  thumbWidth: number = 42;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.containerWidth != this.rangeContainer.nativeElement.clientWidth) {
      this.containerWidth = this.rangeContainer.nativeElement.clientWidth;
      this.applyModal(this.value);
    }
  }

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.containerWidth = this.rangeContainer.nativeElement.clientWidth;
      this.applyModal(this.value);
      this.isVisible = true;
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.previousValue != this.value) {
      this.applyModal(this.value);
    }
  }

  rangeChange(e) {
    this.value = e.target.value;
    this.applyModal(e.target.value);
    this.onRangeChange.emit(this.value);
  }

  private applyModal(currentValue) {
    this.bulletValue = currentValue;
    this.bulletPosition = `${(((currentValue - this.min) / (this.max - this.min)) * (this.containerWidth - this.thumbWidth))}px`;
  }
}
