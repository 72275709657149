<section #select [ngClass]="{'opened' : opened}">
    <div class="select-header" [style.border-color]="noBorder? 'transparent' : borderColor"
        [style.background-color]="backgroundColor" [ngClass]="{'opened' : opened}" (click)="toggle()">
        <div class="select-name">
            <!-- <i class="mr-2" *ngIf="selectName.icon"> <img [src]="selectName.icon"> </i> -->
            <p class="md fw-normal" [style.color]="color">{{ selectName.value }}</p>
        </div>
        <i class="arrow">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.5L6 6.5L11 1.5" [attr.stroke]="arrowColor" stroke-width="1.66667" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </i>
    </div>

    <div class="select-body" [style.height]="bodyHeight + 'px'"
        [style.minWidth]="contentWidth ? contentWidth + 'px' : ''">
        <div class="select-content" #selectContent>
            <p *ngFor="let item of items" (click)="selectItem(item)"
                [ngClass]="{'selected' : item.id == selectedItem?.id}" class="md">
                <!-- <i *ngIf="item.icon" class="mr-2"><img [src]="item.icon"></i> -->
                {{ item.value }}
            </p>
        </div>
    </div>
</section>