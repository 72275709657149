<section *ngIf="displayIsAllowed">
    <div class="container">
        <a [routerLink]="content.logo.url" class="logo">
            <img [src]="content.logo.img" alt="">
        </a>

        <div class="footer-nav-container">
            <div class="navigation-columns">
                <div class="column" *ngFor="let column of content.navigation.columns">
                    <h4 *ngIf="column.title">{{ column.title }}</h4>
                    <ul>
                        <li *ngFor="let link of column.links">
                            <a [routerLink]="link.link">
                                {{ link.name }}
                                <a *ngIf="link.toolTip" [routerLink]="link.toolTip.link" class="tooltip">
                                    {{ link.toolTip.name }}
                                </a>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-terms-container">
            <p>{{ content.legal.title }}</p>
            <div class="legal-links">
                <a [routerLink]="link.link" *ngFor="let link of content.legal.links">{{ link.name }}</a>
            </div>
        </div>
    </div>
</section>