import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-fs-tooltip',
  templateUrl: './fs-tooltip.component.html',
  styleUrls: ['./fs-tooltip.component.scss']
})
export class FsTooltipComponent implements OnInit {

  @ViewChild('pointContainer', { read: ElementRef }) pointContainer: ElementRef;
  @ViewChild('tooltipContent', { read: ElementRef }) tooltipContent: ElementRef;

  shown: boolean;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) { if (window.innerWidth > 1280) { this.maintainClose(e); } }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) { this.maintainPosition(); }

  @HostListener('window:resize', ['$event'])
  onResize() { if (window.innerWidth > 1280) { this.shown = false; } }

  positionY: string;
  positionX: string;

  constructor() { }

  ngOnInit(): void {
  }

  mouseEnter(e) {
    if (window.innerWidth > 1280) {
      this.maintainPosition();
    }
  }

  showTooltip(e) {
    if (window.innerWidth <= 1280) {
      this.shown = true;
    }
  }

  close() {
    this.shown = false;
  }

  private maintainClose(e) {
    this.shown = !(e.target.closest('.tooltip') != this.tooltipContent.nativeElement);
  }

  private maintainPosition() {
    let pointerBoundingRect = this.pointContainer.nativeElement.getBoundingClientRect();
    this.positionX = `${pointerBoundingRect.x + 15}px`;
    this.positionY = `${pointerBoundingRect.y}px`;
  }
}
