import { Component, OnInit } from '@angular/core';
import { createPageContent } from 'src/content/main.content';

@Component({
  selector: 'app-latest-projects',
  templateUrl: './latest-projects.component.html',
  styleUrls: ['./latest-projects.component.scss', '../../shared/styles/styles.scss']
})
export class LatestProjectsComponent implements OnInit {

  private pageNo: number = 1;
  private resultPerPage: number = 4;
  totalCount: number;

  content: any;

  latestProjects: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.content = createPageContent().latestProjects;
    this.totalCount = this.content.items.length;

    this.latestProjects = this.paginate(this.content.items, this.resultPerPage, this.pageNo);
  }

  private paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }
}
