<div class="upload-panel">
    <button class="btn-upload" (click)="showFileUploader()">
        <img src="../../../../../assets/icon-upload.svg" alt="">
    </button>
    {{ btnLabel }}
</div>
<div class="files-list">
    <div class="file-item" *ngFor="let file of files">
        {{ file.name }}
        <button (click)="removeFile(file.fileId)">
            <img src="../../../../assets/fs-remove.svg" alt="">
        </button>
    </div>
</div>
<label for="">{{ label }}</label>

<div class="file-uploader-control">
    <input *ngIf="!allowMultipleFiles" type="file" #fileUploader (change)="onFileChange($event)" />
    <input *ngIf="allowMultipleFiles" type="file" #fileUploader multiple (change)="onFileChange($event)" />
</div>

<div class="fs-file-upload-fade" *ngIf="fileUploaderIsShown">
    <div class="upload-container">
        <div class="upload-panel">
            <img src="../../../../assets/fs-upload.svg" alt="">
            <span>Drop to upload</span>
        </div>
        <ngx-file-drop dropZoneClassName="file-upload-zone" dropZoneLabel="Drag Files or click to select"
            class="file-uploader-container" multiple="{{ allowMultipleFiles }}" (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (click)="openFile()">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

                <!-- <span *ngIf="!errorMessage && !fileName">
                {{ fileSelectText }}
            </span>
            <button hidden #openFileSelectorButton type="button" (click)="openFileSelector()">Browse Files</button>
            <p *ngIf="errorMessage && !fileName" class="error-message">
                {{ errorMessage }}
            </p>
            <p *ngIf="fileName" class="file-name">
                {{ fileName }}
            </p> -->
            </ng-template>
        </ngx-file-drop>
    </div>
</div>