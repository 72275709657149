<!-- <section #tickerContainer>
    <div class="fs-ticker-row">
        <div class="fs-ticker-panel" *ngIf="visible">
            <div class="fs-ticker-item" *ngFor="let ticker of tickers; index as i"
                [style.animation-delay]="getAnimationDelay(i)" [style.animation-duration]="getAnimationDuration()">
                <div [hidden]="!isDisplayed" class="ticker-item-container" #tickerItemContainer>
                    <div class="img-container">
                        <img *ngIf="ticker.imageOne" [src]="ticker.imageOne" alt="">
                    </div>
                    <div class="img-container">
                        <img *ngIf="ticker.imageTwo" [src]="ticker.imageTwo" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section>
    <div class="ticker-inner-container">
        <div class="ticker-row-container" [ngClass]="{'reverse' : directionLeftToRight}">
            <app-fs-ticker-row *ngFor="let tickerRow of tickerRows" [isReverse]="directionLeftToRight"
                [animationDuration]="animationDuration" [tickers]="tickers">
            </app-fs-ticker-row>
        </div>
    </div>
</section>