<div class="project">
    <div class="project-media" [routerLink]="['/work', work?.slug]">
        <img [src]="work?.imageUrl" alt="">
        <div class="fade"></div>
        <a class="view-details">
            View Details
            <img src="../../../../assets/work-view-details.svg" alt="">
        </a>
    </div>

    <div class="project-details">
        <div class="project-details-container">
            <div class="tags">
                <p class="tag" *ngIf="work?.type">{{ work?.type }}</p>
                <p class="tag" *ngFor="let category of work?.categories">{{ category }}</p>
            </div>
            <p class="name">{{ work?.title ?? work?.name }}</p>
            <p class="description">{{ work?.description }}</p>
        </div>
    </div>
</div>