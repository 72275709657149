<header>
    <div class="container">
        <a [routerLink]="content.header.logo.url" class="logo">
            <img [src]="content.header.logo.img" alt="">
        </a>
        <div class="actions">
            <nav>
                <ul>
                    <li *ngFor="let menuItem of content.header.menuItems; index as i">
                        <a routerLinkActive="active" [routerLink]="menuItem.link" [fragment]="menuItem.fragment">{{
                            menuItem.name }}</a>
                    </li>
                </ul>
            </nav>
            <div class="build-now-container" [style.width]="buildContainerWidth + 'px'" (click)="buildNow()">

                <!-- [routerLink]="content.header.contact.link" [fragment]="content.header.contact.fragment" -->

                <a class="contact">{{
                    content.header.contact.name }}</a>
            </div>
        </div>
        <button class="mobile-action-menu" [ngClass]="{'square' : mobileMenuIsVisible}" (click)="toggleMenu()">
            <div *ngIf="!mobileMenuIsVisible" class="mobile-action-menu-stick"></div>
            <div *ngIf="!mobileMenuIsVisible" class="mobile-action-menu-stick"></div>
            <div *ngIf="!mobileMenuIsVisible" class="mobile-action-menu-stick"></div>
            <img *ngIf="mobileMenuIsVisible" src="../../../../assets/icon-close.svg" alt="">
        </button>
    </div>
</header>

<div class="mobile-menu" *ngIf="mobileMenuIsVisible" [ngClass]="{'scrolled' : modalIsScrolled}">
    <div class="mobile-menu-layout">
        <div class="mobile-menu-container" #menuContainer>
            <nav>
                <ul>
                    <li *ngFor="let menuItem of content.header.menuItems; index as i">
                        <a routerLinkActive="active" [routerLink]="menuItem.link" (click)="toggleMenu()"
                            [fragment]="menuItem.fragment">{{
                            menuItem.name }}</a>
                    </li>
                </ul>
            </nav>
            <a (click)="openIntercom()" class="contact">{{ content.header.contact.name }}</a>
            <!-- href="https://portal.faststartup.io/login?step=signUp" -->
        </div>
    </div>
</div>