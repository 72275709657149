import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createPageContent } from 'src/content/build.content';

@Component({
  selector: 'app-fs-build',
  templateUrl: './fs-build.component.html',
  styleUrls: ['./fs-build.component.scss']
})
export class FsBuildComponent implements OnInit {

  @Input('showServices') showServices: boolean;

  content: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.content = createPageContent();
  }

  redirectToBuildPage(link) {
    // this.router.navigate(link);
    // window.location.href = 'http://portal.faststartup.io/login?step=signUp'
    const w = window as any;
    const message = `Hello,
I am interested in hiring FastStartup for my project. Are you available for a quick chat?`;
    w.Intercom('showNewMessage', message);
  }
}
