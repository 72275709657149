import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-fs-input',
  templateUrl: './fs-input.component.html',
  styleUrls: ['./fs-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FsInputComponent),
      multi: true
    }
  ],
})
export class FsInputComponent implements OnInit, ControlValueAccessor {

  @Input() _value = '';
  @Input('label') label: string;
  @Input('prefix') prefix: string;
  @Input('placeholder') placeholder: string = '';

  _focusIn = this._value?.length > 0;

  constructor() { }

  ngOnInit(): void {
  }

  focusIn() {
    this._focusIn = true;
  }

  focusOut() {
    this._focusIn = this._value?.length > 0 || +this.value > 0;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this._focusIn = this._value?.length > 0 || +this.value > 0;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
}
