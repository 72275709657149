import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { EventService } from '../../services/event.service';
import { FadeService } from '../../services/fade.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-fs-dropdown-menu',
  templateUrl: './fs-dropdown-menu.component.html',
  styleUrls: ['./fs-dropdown-menu.component.scss']
})
export class FsDropdownMenuComponent implements OnInit, OnChanges {

  isOpen: boolean;
  dropDownHeight: string;

  dropDownIsScrolled: boolean;

  @Input('selectorLabel') selectorLabel: string;
  @Input('longSelectorLabel') longSelectorLabel: string;

  @Input('showFade') showFade: boolean = true;

  @Input('closeMenu') closeMenu: boolean;
  @Input('closeDropDown') closeDropDown: boolean;

  @Output('onShow') onShow: EventEmitter<void> = new EventEmitter();
  @Output('onClose') onClose: EventEmitter<void> = new EventEmitter();

  @ViewChild("dropDownContent") dropDownContent: ElementRef;
  @ViewChild("dropDownContainer") dropDownContainer: ElementRef;

  @ViewChild("labelText") labelText: ElementRef;

  @HostListener('window:resize', ['$event']) onResize(event) { this.maintainMobileDropDownScroll(); }

  isOverflown: boolean;

  constructor(
    private eventService: EventService,
    private fadeService: FadeService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.eventService.documentClick.subscribe((e) => {
      if (this.dropDownHeight && this.dropDownHeight != '0') {
        if (!this.isDescendantOf(['drop-down-menu-container'], e.target)) {
          this.changeDropdown(null);
        }
      }
    });

    this.eventService.closeDropDown.subscribe((e) => {
      if (e.target?.closest('.drop-down-menu-container') != this.dropDownContainer.nativeElement) {
        if (this.dropDownHeight && this.dropDownHeight != '0') {
          this.changeDropdown(null);
        }
      }
    });


  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.closeMenu?.previousValue != this.closeMenu) {
    //   if (this.closeMenu) {
    //     this.changeDropdown(null);
    //     this.onClose.emit();
    //   }
    // }

    if (changes.closeDropDown?.previousValue != this.closeDropDown) {
      if (this.closeDropDown) {
        this.changeDropdown(null);
        this.onClose.emit();
      }
    }

    this.checkTextOverflow();
  }

  changeDropdown(e) {
    if (e != null) { this.eventService.closeDropDown.emit(e); }
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.dropDownHeight = `${this.dropDownContent.nativeElement.offsetHeight}px`;
      this.closeMenu = true;
      this.onShow.emit();
      if (this.showFade) { this.fadeService.show(); }
      this.maintainMobileDropDownScroll();

    } else {
      this.closeMenu = false;
      this.dropDownHeight = '0';
      this.onClose.emit();
      if (this.showFade) { this.fadeService.hide(); }
    }
  }

  private isDescendantOf(descendedNames, child) {
    var node = child.parentNode;
    while (node != null) {
      let nodeClassNames = node.className ? node.className.split(' ') : [];
      if (descendedNames.indexOf(node.nodeName.toLowerCase()) != -1 ||
        (nodeClassNames.length > 0 && descendedNames.filter(v => nodeClassNames.includes(v)).length > 0)) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  private checkTextOverflow() {
    let element = this.labelText?.nativeElement
    if (element) {
      setTimeout(() => {
        this.isOverflown = (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
      }, 1);
    }
  }

  private maintainMobileDropDownScroll() {
    setTimeout(() => {
      this.dropDownIsScrolled = (this.dropDownContent?.nativeElement.offsetHeight + 100) > window.innerHeight;
      if (this.isOpen) {
        if (window.innerWidth <= 1280) { document.getElementById('fs-body').classList.add('fixed'); }
      } else {
        document.getElementById('fs-body').classList.remove('fixed');
      }
    }, 10);
  }
}
