import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IEstimateService, IEstimateServiceAvailability } from 'src/app/app-estimate/shared/models/estimate.model';

@Component({
  selector: 'app-fs-select-services',
  templateUrl: './fs-select-services.component.html',
  styleUrls: ['./fs-select-services.component.scss']
})
export class FsSelectServicesComponent implements OnInit {

  @Input() service: IEstimateService;
  @Input() serviceAvailability: IEstimateServiceAvailability;
  @Input() webIsAllowed: boolean;
  @Input() mobileIsAllowed: boolean;
  @Input() removeOpacity: boolean;


  @Output() onChange: EventEmitter<IEstimateService> = new EventEmitter();

  services = [];

  constructor() { }

  ngOnInit(): void {
    this.mapService();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes?.service?.currentValue) != JSON.stringify(changes?.service?.previousValue) ||
      changes?.webIsAllowed?.currentValue != changes?.webIsAllowed?.previousValue ||
      changes?.mobileIsAllowed?.currentValue != changes?.mobileIsAllowed?.previousValue
    ) {
      this.mapService();
    }
  }

  serviceChange(service, e) {
    this.service[service.key] = e.target.checked;
    this.onChange.emit(this.service);
  }

  private mapService() {
    this.services = Object.keys(this.service)
      .map(x => {
        return {
          key: x,
          name: this.mapName(x),
          disabled: (x == 'consultation' && this.serviceAvailability.consultationEnabled == false) ||
            (x == 'design' && this.serviceAvailability.designEnabled == false) ||
            (x == 'webEngineering' && this.serviceAvailability.webEngineeringEnabled == false) ||
            (x == 'mobileEngineering' && this.serviceAvailability.mobileEngineeringEnabled == false) ||
            x == 'consultation',
          checked: this.service[x],
          available: x == 'webEngineering' ? this.webIsAllowed :
            x == 'mobileEngineering' ? this.mobileIsAllowed :
              true
        }
      })
      .filter(x => x.available == true);
  }

  private mapName(name) {
    if (name == 'consultation') { return 'Consultation' };
    if (name == 'design') { return 'Design' };
    if (name == 'webEngineering') { return 'Web Engineering' };
    if (name == 'mobileEngineering') { return 'Mobile Engineering' };
    return name;
  }
}
