import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEstimatePriority } from 'src/app/app-estimate/shared/models/estimate.model';

@Component({
  selector: 'app-fs-select-priority',
  templateUrl: './fs-select-priority.component.html',
  styleUrls: ['./fs-select-priority.component.scss']
})
export class FsSelectPriorityComponent implements OnInit {

  @Input() priority: IEstimatePriority;
  @Input() disabled: boolean;
  @Output() onChange: EventEmitter<IEstimatePriority> = new EventEmitter();

  get selectedValues() {
    let response = [];
    if (this.priority.budget) { response.push('Budget'); }
    if (this.priority.speed) { response.push('Speed'); }
    if (this.priority.features) { response.push('Features'); }
    return response;
  }

  constructor() { }

  ngOnInit(): void {
  }

  priorityChange(e) {
    this.onChange.emit({
      features: e.filter(x => x == 'Features').length > 0,
      speed: e.filter(x => x == 'Speed').length > 0,
      budget: e.filter(x => x == 'Budget').length > 0
    } as IEstimatePriority);
  }
}
