<div class="tooltip" #tooltipContent>
    <div class="tooltip-point" (click)="showTooltip($event)" (mouseenter)="mouseEnter($event)" #pointContainer>?</div>
    <div class="tooltiptext" [ngClass]="{'shown' : shown}" [style.left]="positionX" [style.top]="positionY">
        <div class="tooltip-layout">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-content">
                <button class="btn-close" (click)="close()">
                    <img src="../../../../assets/img-close-yellow.png" alt="">
                </button>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>