<section class="fs-section">
    <div class="background-mask">
        <div class="background">
            <div class="guides">
                <div class="guides-container">
                    <!-- <div class="guide"></div>
                    <div class="guide"></div>
                    <div class="guide"></div>
                    <div class="guide"></div>
                    <div class="guide"></div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="layout">
            <ng-content></ng-content>
        </div>
    </div>
</section>