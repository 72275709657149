<section>
    <div class="container heading">
        <h4 class="uppercase">Testimonials</h4>
        <button (click)="navigateTo()">See More</button>
    </div>
    <div class="testimonials-container" #snapRootContainer>
        <div class="testimonials-scroller-crop">
            <div class="testimonials-scroller-content" #snapRoot>
                <div class="testimonials-scroller-platter" #snapContainer>
                    <div class="list-item" #snapItem *ngFor="let testimonial of testimonials; index as i">
                        <div class="card" [ngClass]="{'active' : i + 1 == currentIndex}">
                            <div class="card-content">
                                <div class="testimonial">
                                    <img class="quote-image" src="../../../../assets/quote.svg" alt="">

                                    <div class="author">
                                        <img [src]="testimonial.author.img" alt="">
                                        <div>
                                            <p class="name">{{ testimonial.author.name }}</p>
                                            <p class="position">{{ testimonial.author.position }}</p>
                                        </div>
                                    </div>

                                    <div class="title-and-quote">
                                        <p class="title"> {{ testimonial.title }} </p>
                                        <p class="quote"> {{ testimonial.quote }} </p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-arrows">
            <button class="next" [ngClass]="{'on' : hasNext && isMouseEnter}" (click)="next()">
                <img src="../../../../assets/slider-arrow-1.svg" alt="">
            </button>
            <button class="prev" [ngClass]="{'on' : hasPrev && isMouseEnter}" (click)="prev()">
                <img src="../../../../assets/slider-arrow-1.svg" alt="">
            </button>
        </div>
    </div>
</section>