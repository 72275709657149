import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { setupTestingRouter } from '@angular/router/testing';

@Component({
  selector: 'app-fs-circular-range',
  templateUrl: './fs-circular-range.component.html',
  styleUrls: ['./fs-circular-range.component.scss']
})
export class FsCircularRangeComponent implements OnInit {

  @ViewChild('circle') circle: ElementRef;
  @ViewChild('pickerThumb') pickerThumb: ElementRef;

  @HostListener('document:mousemove', ['$event']) onMouseMove(e) { this.documentMouseMove(e); }
  @HostListener('document:mouseup', ['$event']) onMouseUp(e) { this.documentMouseUp(e); }

  @Output('priorityChange') priorityChange: EventEmitter<any> = new EventEmitter<any>();

  featuresIsActive: boolean = false;
  budgetIsActive: boolean = true;
  sppedIsActive: boolean = true;

  rect = null;
  center = null;

  isDragging = false;
  myRotation = 'rotate(31deg)';
  thumbRotation = 'rotate(-31deg)';
  animating = false;

  adjDeg = null;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.rect = this.circle.nativeElement.getBoundingClientRect();
      this.center = {
        x: this.rect.left + this.rect.width / 2,
        y: this.rect.top + this.rect.height / 2
      };
    }, 10);
  }

  rotate(x, y) {
    var deltaX = x - this.center.x,
      deltaY = y - this.center.y,
      angle = Math.atan2(deltaY, deltaX) * 180 / Math.PI
    return angle;
  }

  circleMouseDown(event) {
    if (event.target == this.circle.nativeElement) {
      this.animating = true;
      this.pickerCircleMouseDown(event, true);
      setTimeout(() => {
        this.animating = false;
      }, 250);
    }
  }

  pickerCircleMouseDown(event, force = false) {
    if (event.target == this.pickerThumb.nativeElement || force) {
      event.preventDefault();
      this.isDragging = true;
      this.documentMouseMove(event, force);
    }
  }

  documentMouseMove(event, force = false) {
    if (this.isDragging) {
      let deg = this.rotate(event.pageX, event.pageY);

      if (!force) {
        this.myRotation = 'rotate(' + deg + 'deg)';
        this.thumbRotation = 'rotate(' + (deg * -1) + 'deg)';
      }

      this.adjDeg = deg;

      if (force) { this.isDragging = false; }
    }
  }

  documentMouseUp(event) {
    this.isDragging = false;

    if (this.adjDeg) {

      let _adjReg = this.adjDeg;
      let _deg = null;


      if ((_adjReg >= -90 && _adjReg <= -45) || (_adjReg > -145 && _adjReg < -90)) { // Budget
        _deg = -90;
        this.changeMe(true, false, true);
      }

      if ((_adjReg >= -45 && _adjReg <= 31) || (_adjReg > 31 && _adjReg < 60)) { // Budget
        _deg = 31;
        this.changeMe(true, true, false);
      }

      if ((_adjReg >= 61 && _adjReg <= 180) || (_adjReg >= -180 && _adjReg < -145)) { // Budget
        _deg = 150;
        this.changeMe(false, true, true);
      }

      this.myRotation = 'rotate(' + _deg + 'deg)';
      this.thumbRotation = 'rotate(' + (_deg * -1) + 'deg)';

      this.adjDeg = null;
    }
  }

  private changeMe(budget, speed, features) {
    this.budgetIsActive = budget;
    this.sppedIsActive = speed;
    this.featuresIsActive = features;

    this.priorityChange.emit({
      budget: budget,
      speed: speed,
      features: features
    });
  }
}