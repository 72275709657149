<section>
    <div class="container heading">
        <h4 class="uppercase">Services</h4>
        <button (click)="navigateTo()">See More</button>
    </div>
    <div class="container">
        <div class="services">
            <div class="service" *ngFor="let service of services">
                <div class="service-name">
                    <p>{{ service.name }}</p>
                </div>

                <div class="tasks">
                    <p class="task" *ngFor="let task of service.tasks">
                        {{ task }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>