<app-introduction></app-introduction>

<app-trusted-by></app-trusted-by>

<app-testimonials></app-testimonials>

<app-work></app-work>

<app-services></app-services>

<app-team></app-team>

<app-latest-projects></app-latest-projects>

<app-fs-build></app-fs-build>

<app-fs-contact></app-fs-contact>