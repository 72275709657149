import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-fs-file-upload',
  templateUrl: './fs-file-upload.component.html',
  styleUrls: ['./fs-file-upload.component.scss']
})
export class FsFileUploadComponent implements OnInit {

  @Input('label') label: string;
  @Input('btnLabel') btnLabel: string;
  @Input('metaKey') metaKey: string;
  @Input('allowMultipleFiles') allowMultipleFiles: boolean;

  @Output('onFileSelect') onFileSelect: EventEmitter<any> = new EventEmitter();
  @Output('onFileRemove') onFileRemove: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileUploader') fileUploader: ElementRef;

  files: any[] = [];
  fileUploaderIsShown: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  selectFile() {
    this.fileUploader.nativeElement.click();
  }

  onFileChange(e) {
    if (this.allowMultipleFiles == false) {
      this.files.forEach(file => {
        this.removeFile(file.fileId);
      });
    }

    let files = Array.from(e.target.files);
    if (files.length > 0) {
      files.forEach((file: any) => {
        this.manageFileDrop(file);
      });
    }
  }

  removeFile(id) {
    this.files = this.files.filter(x => x.fileId != id);
    this.onFileRemove.emit({
      metaKey: this.metaKey,
      fileId: id
    });
  }

  showFileUploader() {
    window.scrollTo(0, 0);
    this.fileUploaderIsShown = true;
    document.body.classList.add("fixed");
  }

  closeFileUploader() {
    this.fileUploaderIsShown = false;
    document.body.classList.remove("fixed");
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.manageFileDrop(file);
        });
        setTimeout(() => { this.closeFileUploader(); }, 100);
      }
    }
  }

  public fileOver(event) {
    this.clear();
  }

  public fileLeave(event) {
    this.clear();
  }

  openFile() {
    this.selectFile();
  }

  private clear() {

  }

  private manageFileDrop(file) {
    let _file = {
      metaKey: this.metaKey,
      fileId: this.files.length + 1,
      name: file.name,
      size: file.size,
      file: file,
      type: file.type,
    };
    this.files.push(_file);
    this.onFileSelect.emit(_file);
  }
}
