<div class="scroll-actions" *ngIf="canScroll">
    <button class="prev" (click)="scrollBack()" [disabled]="!canScrollPrev">
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.23682 5.5L13.0789 5.5" stroke="#212121" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M5.46614 9.44727L1.23682 5.4999L5.46614 1.55253" stroke="#212121" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </button>
    <button class="next" (click)="scrollNext()" [disabled]="!canScrollNext">
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.23682 5.5L13.0789 5.5" stroke="#212121" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M5.46614 9.44727L1.23682 5.4999L5.46614 1.55253" stroke="#212121" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </button>
</div>

<section #timelineContainer>
    <div #sectionContainer class="section-container" [style.width]="getContainerWidth()">
        <div class="sprints">
            <div class="sprint" [style.width]="getSprintWidth()" *ngFor="let sprint of sprints; index as i">
                <div class="sprint-name">{{ sprint.name }} <span style="display: inline-block; margin-left: 4px" *ngIf="i > 0"> (WEEK {{ (i * 3) - 2 }} - {{ (i * 3) }})</span></div>
            </div>
        </div>
        <div class="services">
            <div class="service" *ngFor="let service of timelines">
                <div class="service-name" [style.marginLeft]="serviceMargin(service)"
                    [style.width]="serviceWidth(service)">
                    {{ service.name }}</div>
            </div>
        </div>
    </div>
</section>

<div class="timeline-note">
    <p>* Each Consultation is 1-week long. Each Sprint is 3-week long.</p>
</div>

<div class="timeline-button-container" *ngIf="allowFullScreen">
    <button class="timeline-button" (click)="seeInFullSchreen()">
        See timeline in fullscreen 
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25317 2.69629C1.25317 2.144 1.70089 1.69629 2.25317 1.69629H10.3332C10.8855 1.69629 11.3332 2.144 11.3332 2.69629V16.5363C11.3332 17.0886 10.8855 17.5363 10.3332 17.5363H2.25318C1.70089 17.5363 1.25317 17.0886 1.25317 16.5363V2.69629Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.45318 23.2964C7.65789 23.2964 7.01318 22.6517 7.01318 21.8564" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.4131 23.2964C22.2084 23.2964 22.8531 22.6517 22.8531 21.8564" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.01318 17.5361V18.9761" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.8533 17.5361V18.9761" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3333 23.2959H13.4933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.3733 23.2959H18.5333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.4131 13.2158C22.2084 13.2158 22.8531 13.8605 22.8531 14.6558" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3333 13.2158H13.4933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.3733 13.2158H18.5333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.3546 2.41602L13.7044 4.06722C13.5693 4.20209 13.4934 4.38514 13.4934 4.57602C13.4934 4.76689 13.5693 4.94994 13.7044 5.08482L15.3546 6.73602" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.8132 9.19531L19.4644 10.8456C19.5993 10.9806 19.7824 11.0565 19.9732 11.0565C20.1641 11.0565 20.3472 10.9806 20.482 10.8456L22.1332 9.19531" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.4932 4.57617H17.0932C18.6837 4.57617 19.9732 5.86559 19.9732 7.45617V11.0562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>            
    </button>
</div>