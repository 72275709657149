<section>
    <div class="service" *ngFor="let service of services">
        <h4>{{ service.name }}</h4>
        <div class="features">
            <p class="feature" *ngFor="let feature of service.features">
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.96777 7.65838L8.24141 15L20.2904 1" stroke="#FCEA10" stroke-width="3" />
                </svg>
                {{ feature }} <app-questionmark-popup></app-questionmark-popup>
            </p>
        </div>
    </div>
</section>