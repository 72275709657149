<div class="fs-range-container" #rangeContainer [ngClass]="{'visible' : isVisible}">
    <div class="fs-range-slider">
        <span class="range-modal" [style.left]="bulletPosition">
            <h2>{{ bulletValue }}</h2>
        </span>
        <input class="fs-range" type="range" [value]="value" [min]="min" [max]="max" (input)="rangeChange($event)">
    </div>
    <div class="fs-range-footer">
        <h5>{{ min }} sprint</h5>
        <h5>{{ max }} sprints</h5>
    </div>
</div>