<section>
    <div class="container heading">
        <h4 class="uppercase">Team</h4>
        <button (click)="navigateTo()">See More</button>
    </div>

    <div class="teams-container" #snapRootContainer>
        <div class="teams-scroller-crop">
            <div class="teams-scroller-content" #snapRoot>
                <div class="teams-scroller-platter" #snapContainer>
                    <div class="list-item" #snapItem *ngFor="let teamMember of teamMembers; index as i">
                        <div class="card">
                            <div class="card-content">
                                <div class="team">
                                    <div class="media">
                                        <img [src]="teamMember.imgUrl" alt="">
                                    </div>
                                    <p class="name">{{ teamMember.fullName }}</p>
                                    <p class="position">{{ teamMember.position }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-arrows">
            <button class="next" [ngClass]="{'on' : hasNext && isMouseEnter}" (click)="next()">
                <img src="../../../../assets/slider-arrow-1.svg" alt="">
            </button>
            <button class="prev" [ngClass]="{'on' : hasPrev && isMouseEnter}" (click)="prev()">
                <img src="../../../../assets/slider-arrow-1.svg" alt="">
            </button>
        </div>
    </div>
</section>