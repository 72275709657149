import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { createPageContent } from 'src/content/contact.content';

@Component({
  selector: 'app-fs-contact',
  templateUrl: './fs-contact.component.html',
  styleUrls: ['./fs-contact.component.scss']
})
export class FsContactComponent implements OnInit {

  contactUsForm: FormGroup;

  content: any;
  inquiryFormIsOpen: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.content = createPageContent();
    this.buildForm();
  }

  toggleContactForm() {
    this.inquiryFormIsOpen = !this.inquiryFormIsOpen;
  }

  submit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.inquiryFormIsOpen = false;
      this.contactUsForm.reset();
    }
  }

  private buildForm() {
    this.contactUsForm = this.formBuilder.group({
      formOne: this.formBuilder.group({
        reason: ['', [Validators.required]]
      }),
      formTwo: this.formBuilder.group({
        timeline: ['', [Validators.required]],
        projectType: ['', [Validators.required]],
        clientName: ['', [Validators.required]],
        companyName: ['', [Validators.required]],
        businessEmail: ['', [Validators.required]],
        budget: ['', [Validators.required]],
        projectDescription: ['', [Validators.required]]
      })
    });
  }
}
