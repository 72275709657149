<section>
    <div class="container">

        <div class="header-container">
            <h3 [innerHtml]="content.sectionTitle"></h3>
        </div>

        <div class="inner-container">
            <p [innerHtml]="content.description"></p>
            <div class="btn-build-now-container">
                <app-fs-skew-button (click)="redirectToBuildPage(content.btnBuild.link)">
                    <div class="button-content">
                        {{ content.btnBuild.label }} <img [src]="content.btnBuild.img" alt="">
                    </div>
                </app-fs-skew-button>
            </div>
        </div>
    </div>
</section>