import { Component, ElementRef, HostBinding, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, fromEvent } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss', '../../shared/styles/styles.scss']
})
export class TestimonialsComponent implements OnInit {

  currentIndex = 1;
  isMouseEnter: boolean;

  @ViewChildren('snapItem') snapItems: QueryList<ElementRef>;
  @ViewChild('snapContainer') snapContainer: ElementRef;
  @ViewChild('snapRoot') snapRoot: ElementRef;
  @ViewChild('snapRootContainer') snapRootContainer: ElementRef;

  private tickerInitialWidth = 620;
  private tickerInitialMargin = 32;

  @HostBinding('style.--tickerWidth') private tickerWidth: string = `${this.tickerInitialWidth}px`;
  @HostBinding('style.--tickerMargin') private tickerMargin: string = `${this.tickerInitialMargin}px`;

  testimonials = [
    { title: 'The team kept the project on track and on time.', quote: 'I hired FastStartup, and over the next few weeks, they took my ideas and built an amazing prototype. The team kept the project on track and on time. And the designers I worked with were skilled, empathetic and hard working!', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653943663/testimonial/rodney_urnjva.jpg', name: 'Rodney', position: 'Social | Personal Development' } },
    { title: 'On time and on budget with a great product.', quote: 'Melvyn and the team were on time and on budget with a great product. Highly recommended.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1656300578/testimonial/waseem_defsst.jpg', name: 'Waseem', position: 'Enterprise | Health' } },
    { title: 'Exceptional job on this project.', quote: 'Melvyn and his team did an exceptional job on this project. I would highly recommend him, and he went above and beyond with each interaction.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653942421/testimonial/patrick_y2daxa.jpg', name: 'Patrick', position: 'SaaS | Business Analytics' } },
    { title: 'The work product was better than what I could have expected.', quote: 'FastStartup was exceptional; the work product was better than what I could have expected. The creativity and presentation were the best in class for sure.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653942932/testimonial/paul_pioqik.jpg', name: 'Paul', position: 'SaaS | Information Security' } },
    { title: 'Highly recommend FastStartup as they will get it done right the first time.', quote: 'FastStartup has exceeded every possible expectation for the money spent. Highly recommend FastStartup as they will get it done right the first time.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653942866/testimonial/daniel_nr8nml.jpg', name: 'Daniel', position: 'Marketplace | Professional Development' } },
    { title: 'The team is very thorough and considerate.', quote: 'It is great to work with FastStartup. The team is very thorough and considerate. Look forward to doing much more work together.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653944473/testimonial/alejandro_mc2v6n.png', name: 'Alejandro', position: 'SaaS | Luxury Retail' } },
    { title: 'Melvyn and his team are excellent!', quote: 'Melvyn and his team are excellent! I am taking some time off of this project, but I will definitely hire him again when I return to it. :)', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653945742/testimonial/keirra_eibpzt.png', name: 'Keirra', position: 'Marketplace | Personal Services' } },
    { title: 'Amazing developer and project manager.', quote: 'Amazing developer and project manager. Very organized.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653945955/testimonial/reginald_oaznqf.jpg', name: 'Reginald', position: 'SaaS | Market Research' } },
    { title: 'Great resource to help with our needs.', quote: 'FastStartup was a great resource to help with our needs. I would use them again without delay.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653946224/testimonial/bob_vgkcpx.jpg', name: 'Bob', position: 'SaaS | Marketing & Publishing' } },
    { title: 'Amazing quality. Great work!', quote: 'Amazing quality. Great work!', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653944794/testimonial/kyle_i2eupm.jpg', name: 'Kyle', position: 'Marketplace | Luxury Rental' } },
    { title: 'Beyond excellent.', quote: 'FastStartup was beyond excellent. The completed project was on time, extremely thorough, and top-notch. I look forward to using them again in the future.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653947058/testimonial/chris_jkl2li.jpg', name: 'Chris', position: 'Communications | Contents & Publishing' } },
    { title: 'Highly recommend if you need some algorithmic leadership on your project.', quote: 'Highly recommend if you need some algorithmic leadership on your project. Melvyn also has a strong understanding of the working dynamics and a strong sense of practicality when launching products.', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653946846/testimonial/zac_myjfr1.png', name: 'Zac', position: 'Marketplace | Social' } },
    { title: 'Well worth their fee levels.', quote: 'FastStartup is amazing! The team analyzed a large data set with complex machine learning technologies and came back with a prediction model of incredible accuracy. It took other data scientists very long time to even come close. Well worth their fee levels."', author: { img: 'https://res.cloudinary.com/faststartup/image/upload/v1653946446/testimonial/wolfram_uoenpc.jpg', name: 'Wolfram', position: 'Machine Learning | Business Analytics' } },
  ];

  hasNext: boolean;
  hasPrev: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.maintainTickerWidthAndMargin();

    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.maintainTickerWidthAndMargin();
      })
  }

  ngAfterViewInit() {

    fromEvent(this.snapRootContainer.nativeElement, 'mouseenter')
      .subscribe(() => {
        this.isMouseEnter = true;
        this.maintainNextAndPrevArrows();
      })

    fromEvent(this.snapRootContainer.nativeElement, 'mouseleave')
      .subscribe(() => {
        this.isMouseEnter = false;
        this.maintainNextAndPrevArrows();
      })

    fromEvent(this.snapRoot.nativeElement, 'scroll')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.isMouseEnter = true;
        this.maintainNextAndPrevArrows();
      })
  }

  next() {
    this.snapItems.find((_, i) => i == (this.currentIndex + 1) - 1).nativeElement
      .scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
  }

  prev() {
    this.snapItems.find((_, i) => i == (this.currentIndex - 1) - 1).nativeElement
      .scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
  }

  navigateTo() {
    this.router.navigate(['/testimonials'])
  }

  private maintainTickerWidthAndMargin() {
    const windowWidth = window.innerWidth;
    this.tickerWidth = `${Math.min(window.innerWidth * 0.80, this.tickerInitialWidth)}px`;

    if (windowWidth >= 320 && windowWidth <= 1280) {
      this.tickerMargin = '30px'
    } else {
      this.tickerMargin = `${this.tickerInitialMargin}px`;
    }
  }

  private maintainNextAndPrevArrows() {
    const positionX = this.snapContainer?.nativeElement?.getBoundingClientRect()?.x;

    this.hasNext = (positionX != (this.snapContainer?.nativeElement?.scrollWidth - this.snapContainer?.nativeElement?.offsetWidth) * -1);
    this.hasPrev = positionX != 0;


    let currentIndex = 0;
    const tickerWidth = parseInt(this.tickerWidth.replace('px', '')) + parseInt(this.tickerMargin.replace('px', ''));

    if (positionX == 0) {
      currentIndex = 1;
    } else {
      currentIndex = (positionX * -1 / (tickerWidth)) + 1;
    }

    this.currentIndex = Math.floor(currentIndex);

    console.log(this.currentIndex);
  }
}
