<section *ngIf="ranges">
    <div class="speed-range-arrows">
        <div class="speed-range-arrow prev" (click)="rangePrev()"></div>
        <div class="speed-range-arrow next" (click)="rangeNext()"></div>
    </div>

    <input class="speed-range" type="range" [value]="index" (input)="rangeChange($event.target.value)" [min]="min"
        [max]="max">

    <div class="speed-range-info-container">
        <div class="speed-range-info" *ngFor="let range of ranges; index as i"
            [ngClass]="{ 'active' : value == i + 1 }"> {{ range.value }} </div>
    </div>
</section>