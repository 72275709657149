<section>
    <div class="container heading">
        <h4 class="uppercase">Work</h4>
        <button (click)="navigateTo()">See More</button>
    </div>
    <div class="container work-list">
 
        <app-fs-work-item *ngFor="let workItem of workList" [work]="workItem"></app-fs-work-item>
 
    </div>
</section>