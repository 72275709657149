import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-dashed-header',
  templateUrl: './fs-dashed-header.component.html',
  styleUrls: ['./fs-dashed-header.component.scss']
})
export class FsDashedHeaderComponent implements OnInit {

  @Input('backgroundColor') backgroundColor: string;
  @Input('dashColor') dashColor: string;
  @Input('dashCount') dashCount: number = 3;
  @Input('leftDashes') leftDashes: boolean = false;

  @HostListener('window:orientationchange', ['$event']) onResize(event) { this.maintainResponsiveHeight(); }

  sectionHeight: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.maintainResponsiveHeight();
  }

  private maintainResponsiveHeight() {
    setTimeout(() => {
      if (window.innerWidth <= 900) {
        this.sectionHeight = `${Math.max(window.innerHeight - 60, 500)}px`;
      } else {
        this.sectionHeight = '';
      }
    }, 100);
  }
}
