import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss', '../../shared/styles/styles.scss']
})
export class ServicesComponent implements OnInit {

  services = [
    { name: 'Consultation', tasks: ['Discovery Call', 'Requirement Gathering', 'Go-To-Market Strategy', 'Mindmap'] },
    { name: 'Design', tasks: ['Research & Planning', 'Visual Identity', 'Product Design', 'Design Implementation'] },
    { name: 'Engineering', tasks: ['Initialization', 'Testing', 'Frontend', 'Backend & DevOps'] }
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigateTo() {
    this.router.navigate(['/services']);
  }
}
