import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-c-range',
  templateUrl: './fs-c-range.component.html',
  styleUrls: ['./fs-c-range.component.scss']
})
export class FsCRangeComponent implements OnInit {

  @Input('value') value: any;
  @Input('ranges') ranges: any[];
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

  index: number;
  min: number;
  max: number;

  constructor() { }

  ngOnInit(): void {
    this.min = 1;
    this.max = this.ranges.length;
    this.index = this.ranges.findIndex(x => x.key == this.value);
    let emitChange = this.index != -1;
    this.index += 1;
    if (this.index == 0 || this.index == -1) { this.index = 1; }

    if (emitChange) { this.rangeChange(this.index); }
  }

  rangeNext() {
    this.index += 1;
    this.rangeChange(this.index);
  }

  rangePrev() {
    this.index -= 1;
    this.rangeChange(this.index);
  }

  rangeChange(index) {
    this.value = this.ranges[index - 1].key;
    this.index = index;
    this.onChange.emit(this.value);
  }
}
