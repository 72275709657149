export function createPageContent() {
  return {
    header: {
      logo: {
        url: '/',
        img: '../../../../assets/logo.svg',
      },
      menuItems: [
        {
          name: 'Work',
          link: ['/work'],
          subMenu: {
            rows: [
              {
                links: [{ name: 'Video', link: ['/work'] }],
              },
              {
                links: [{ name: 'Marketplace', link: ['/work'] }],
              },
              {
                links: [{ name: 'On Demand', link: ['/work'] }],
              },
              {
                links: [{ name: 'Social', link: ['/work'] }],
              },
              {
                links: [{ name: 'SaaS', link: ['/work'] }],
              },
              {
                links: [{ name: 'Education', link: ['/work'] }],
              },
              {
                links: [{ name: 'Finance', link: ['/work'] }],
              },
            ],
          },
        },
        {
          name: 'Testimonials',
          link: ['/testimonials'],
        },
        {
          name: 'Services',
          link: ['/services'],
          subMenu: {
            rows: [
              {
                title: '',
                links: [
                  // { name: 'Project Management', link: ['/flow'] },
                  // { name: 'Budget', link: ['/flow'] },
                  // { name: 'Timeline', link: ['/flow'] },
                ],
              },
            ],
          },
        },
        // {
        //   name: 'Flow',
        //   link: ['/services'],
        //   subMenu: {
        //     rows: [
        //       {
        //         title: '',
        //         links: [
        //           { name: 'Project Management', link: ['/flow'] },
        //           { name: 'Budget', link: ['/flow'] },
        //           { name: 'Timeline', link: ['/flow'] },
        //         ],
        //       },
        //     ],
        //   },
        // },
        // {
        //   name: 'Services',
        //   link: ['/services'],
        //   subMenu: {},
        // },
        {
          name: 'Team',
          link: ['/team'],
          subMenu: {},
        },
      ],

      contact: {
        name: 'Build Now',
        link: ['https://portal.faststartup.io/login?step=signUp'],
        fragment: null,
      },
    },
    introduction: {
      title: `Big <span>Ideas.</span> <br> Built <span>Fast.</span>`,
      subTitle: `Entrepreneurs with big ideas work with FastStartup <br> to build innovative startup websites and mobile <br> applications.`,
      cta: {
        title: 'Learn More',
        link: ['/work'],
      },
    },
    trustedBy: {
      title: 'Trusted by',
      companies: [
        { img: '../../../../assets/clients/lol.png' },
        { img: '../../../../assets/clients/nifty.png' },
        { img: '../../../../assets/clients/vybe.png' },
        { img: '../../../../assets/clients/cosmunity.png' },
        { img: '../../../../assets/clients/aizen.png' },
        { img: '../../../../assets/clients/goetzpartners.png' },
        { img: '../../../../assets/clients/wepitch.png' },
        { img: '../../../../assets/clients/rabbit.png' },
      ],
    },
    latestProjects: {
      title: 'Latest',
      items: [
        {
          title:
            'Startups are evolving to manage growth alongside profitability',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644655851/blog/startups-are-evolving-to-manage-growth-alongside-profitability_aflp0c.jpg',
          createDate: '',
          location: 'TechCrunch',
          btn: {
            label: 'View Details',
            link: [
              'https://techcrunch.com/2022/02/11/startups-are-evolving-to-manage-growth-alongside-profitability/',
            ],
          },
        },
        {
          title:
            'How non-technical co-founders built an enormously successful custom platform',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644659121/blog/How_Co-Founders_Without_Tech_Backgrounds_Built_an_Enormously_Successful_Custom_Platform_sxt6y3.jpg',
          createDate: '',
          location: 'Entrepreneur',
          btn: {
            label: 'View Details',
            link: ['https://www.entrepreneur.com/article/377263'],
          },
        },
        {
          title: 'How to avoid 6 critical tech-startup failures (and why you should know)',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644658534/blog/how-to-avoid-6-critical-tech-startup-failures_g0oihr.jpg',
          createDate: '',
          location: 'Entrepreneur',
          btn: {
            label: 'View Details',
            link: ['https://www.entrepreneur.com/article/374351'],
          },
        },
        {
          title: 'Building a startup that will last without negatively impacting short-term growth',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644655850/blog/building-a-startup-that-will-last_omgshg.jpg',
          createDate: '',
          location: 'Harvard Business Review',
          btn: {
            label: 'View Details',
            link: ['https://hbr.org/2019/07/building-a-startup-that-will-last'],
          },
        },
        {
          title:
            'This Seattle startup just raised $2.2M for an app that detects ear infections',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644655850/blog/this-seattle-startup-just-raised-dollar22m-for-an-app-that-detects-ear-infections_u32xuw.jpg',
          createDate: '',
          location: 'Geekwire',
          btn: {
            label: 'View Details',
            link: [
              'https://www.geekwire.com/2022/this-seattle-startup-just-raised-2-2m-for-an-app-that-detects-ear-infections/',
            ],
          },
        },
        {
          title:
            '‘It’s all just wild’: Tech startups reach a new peak of froth',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644658110/blog/its-all-just-wild-tech-start-ups-reach-a-new-peak-of-froth_aksujm.jpg',
          createDate: '',
          location: 'New York Times',
          btn: {
            label: 'View Details',
            link: [
              'https://www.nytimes.com/2022/01/19/technology/tech-startup-funding.html',
            ],
          },
        },
        {
          title: 'Tech startup challenges (and how to overcome them)',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644655851/blog/tech-startup-challenges-and-how-to-overcome-them.jpg',
          createDate: '',
          location: 'Business News Daily',
          btn: {
            label: 'View Details',
            link: [
              'https://www.businessnewsdaily.com/6265-tech-startup-challenges.html',
            ],
          },
        },
        {
          title: 'How to accelerate your success as a female founder',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644658888/blog/how-to-accelerate-your-success-as-a-female-founder.jpg',
          createDate: '',
          location: 'Entrepreneur',
          btn: {
            label: 'View Details',
            link: ['https://www.entrepreneur.com/article/382273'],
          },
        },
        {
          title: 'Top 6 reasons why silicon valley is a startup heaven',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644657167/blog/why-is-silicon-valley-a-startup-heaven_lnf4sm.jpg',
          createDate: '',
          location: 'Investopedia',
          btn: {
            label: 'View Details',
            link: [
              'https://www.investopedia.com/articles/personal-finance/061115/why-silicon-valley-startup-heaven.asp',
            ],
          },
        },
        {
          title:
            'How online marketplaces are making secondhand fashion a first choice',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644655851/blog/how-online-marketplaces-are-making-secondhand-fashion-a-first-choice_v2vhcc.jpg',
          createDate: '',
          location: 'McKinskey & Company',
          btn: {
            label: 'View Details',
            link: [
              'https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/how-online-marketplaces-are-making-secondhand-fashion-a-first-choice',
            ],
          },
        },
        {
          title: 'Vital signs: The growing impact of digital health innovation',
          img: 'https://res.cloudinary.com/faststartup/image/upload/v1644655851/blog/vital-signs-the-growing-impact-of-digital-health-innovation_czf495.jpg',
          createDate: '',
          location: 'McKinskey & Company',
          btn: {
            label: 'View Details',
            link: [
              'https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/vital-signs-the-growing-impact-of-digital-health-innovation',
            ],
          },
        },
        {
          title:
            'How to build an inclusive virtual culture with these 4 effective strategies',
          img: 'https://p153.p0.n0.cdn.getcloudapp.com/items/5zudLykQ/993%2C422%20Event%20Management%20Stock%20Photos%2C%20Pictures%20%26amp%3B%20Royalty-Free%20Images%20-%20iStock%202020-12-17%20at%203.09.35%20PM.jpg?source=viewer&v=17386db2b02622ad09043118afc73376',
          createDate: '',
          location: 'Forbes',
          btn: {
            label: 'View Details',
            link: [
              'https://www.forbes.com/sites/heidilynnekurter/2020/05/21/how-to-build-an-inclusive-virtual-culture-with-these-4-effective-strategies/?sh=506cdd801062',
            ],
          },
        },
      ],
    },
  };
}
