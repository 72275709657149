import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-fs-ticker',
  templateUrl: './fs-ticker.component.html',
  styleUrls: ['./fs-ticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FsTickerComponent implements OnInit, AfterViewInit {

  @ViewChild("tickerContainer") tickerContainer: ElementRef;
  @ViewChildren('tickerItemContainer') tickerItemContainers: QueryList<ElementRef>;

  @Input('directionLeftToRight') directionLeftToRight: boolean;
  @Input('tickers') tickers: any[] = [];
  @Input('animationDuration') animationDuration = 120;
  originalTickers: any[];
  isDisplayed: boolean = false;

  tickerWidth = 550;
  animationAreaWidth = 0;
  marginBetweenTickers = 20;

  visible: boolean;

  tickerRows = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  @ViewChild('tickerRowContainer') tickerRowContainer: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.initTickers();
  }

  getAnimationDelay(index) {
    // return `-${(this.animationDuration * (this.tickerWidth + this.marginBetweenTickers) / this.animationAreaWidth) * index}s`;
  }

  getAnimationDuration() {
    // return `${this.animationDuration}s`;
  }

  getTickerWidth() {
    // return `${this.tickerWidth}px`;
  }

  getTickerImageWidth(ticker) {
    // let hasBoth = ticker.imageOne && ticker.imageTwo;
    // return hasBoth ? `calc(50% - ${this.marginBetweenTickers / 2}px)` : '100%';
  }

  private initTickers() {
    // this.animationAreaWidth = (this.tickerWidth + this.marginBetweenTickers) * this.tickers.length;
    // let normilizedArea = Math.max(this.animationAreaWidth, window.innerWidth);
    // let itemsCountInArrea = normilizedArea / (this.tickerWidth + 50);

    // if (this.tickers.length < itemsCountInArrea) {
    //   if (!this.originalTickers) { this.originalTickers = this.tickers; }

    //   let dublicationIndex = parseInt((itemsCountInArrea / this.tickers.length).toFixed());

    //   for (let index = 0; index < dublicationIndex; index++) {
    //     this.tickers = this.tickers.concat(this.originalTickers);
    //   }

    //   this.animationAreaWidth = (this.tickerWidth + this.marginBetweenTickers) * this.tickers.length;
    // }

    // this.tickerContainer.nativeElement.style.setProperty("--animation-from", `${(this.directionLeftToRight ? -this.animationAreaWidth : 0)}px`);
    // this.tickerContainer.nativeElement.style.setProperty("--animation-to", `${(this.directionLeftToRight ? 0 : -this.animationAreaWidth)}px`);
    // this.visible = true;
    // this.changeDetectorRef.detectChanges();
  }
}
