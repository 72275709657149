import { Component, ElementRef, HostBinding, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss', '../../shared/styles/styles.scss']
})
export class TeamComponent implements OnInit {

  private currentIndex = 1;
  isMouseEnter: boolean;

  @ViewChildren('snapItem') snapItems: QueryList<ElementRef>;
  @ViewChild('snapContainer') snapContainer: ElementRef;
  @ViewChild('snapRoot') snapRoot: ElementRef;
  @ViewChild('snapRootContainer') snapRootContainer: ElementRef;

  private tickerInitialSize = 390;
  private tickerInitialMargin = 30;

  @HostBinding('style.--tickerWidth') private tickerWidth: string = `${this.tickerInitialSize}px`;
  @HostBinding('style.--tickerHeight') private tickerHeight: string = `${this.tickerInitialSize}px`;
  @HostBinding('style.--tickerMargin') private tickerMargin: string = `${this.tickerInitialMargin}px`;

  hasNext: boolean;
  hasPrev: boolean;

  teamMembers = [
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185179/team/fs-aaron_ce7bft.jpg', fullName: 'Aaron', position: 'Design' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185178/team/fs-alexander_wxq4gg.jpg', fullName: 'Alexander', position: 'Engineering' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185180/team/fs-blazej_x0ztod.jpg', fullName: 'Blazej', position: 'Design' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1671117486/team/fs-jibari_nociq0.png', fullName: 'Jibari', position: 'Design' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1653329438/team/fs-jude_gqdduq.png', fullName: 'Jude', position: 'Engineering' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639023507/team/fs-julie_obq2bw.png', fullName: 'Julie', position: 'Company Dog' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185179/team/fs-justin_fumdyz.jpg', fullName: 'Justin', position: 'Success' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185180/team/fs-kate_m9rw7q.jpg', fullName: 'Kate', position: 'Success' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185185/team/fs-melvyn_xypau5.jpg', fullName: 'Melvyn', position: 'Success' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1654232493/team/fs-muhammad_gjdhy2.png', fullName: 'Muhammad', position: 'Engineering' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1671117483/team/fs-oleh_g6x5cx.jpg', fullName: 'Oleh', position: 'Design' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185181/team/fs-osama_m8drdq.jpg', fullName: 'Osama', position: 'Design' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1654557446/team/fs-sherry_grsx1z.png', fullName: 'Sherry', position: 'Engineering' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185182/team/fs-tato_atlmle.jpg', fullName: 'Tato', position: 'Engineering' },
    { imgUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1639185181/team/fs-vlad_i8i4yr.jpg', fullName: 'Vlad', position: 'Design' },
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.maintainTickerWidthAndMargin();

    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.maintainTickerWidthAndMargin();
      })
  }

  ngAfterViewInit() {

    fromEvent(this.snapRootContainer.nativeElement, 'mouseenter')
      .subscribe(() => {
        this.isMouseEnter = true;
        this.maintainNextAndPrevArrows();
      })

    fromEvent(this.snapRootContainer.nativeElement, 'mouseleave')
      .subscribe(() => {
        this.isMouseEnter = false;
        this.maintainNextAndPrevArrows();
      })

    fromEvent(this.snapRoot.nativeElement, 'scroll')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.isMouseEnter = true;
        this.maintainNextAndPrevArrows();
      })
  }

  next() {
    this.snapItems.find((_, i) => i == (this.currentIndex + 1) - 1).nativeElement
      .scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
  }

  prev() {
    this.snapItems.find((_, i) => i == (this.currentIndex - 1) - 1).nativeElement
      .scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
  }

  navigateTo() {
    this.router.navigate(['/team']);
  }

  private maintainTickerWidthAndMargin() {
    const windowWidth = window.innerWidth;
    this.tickerWidth = `${Math.min(window.innerWidth * 0.80, this.tickerInitialSize)}px`;
    this.tickerHeight = this.tickerWidth;

    if (windowWidth >= 320 && windowWidth <= 1280) {
      this.tickerMargin = '20px'
    } else {
      this.tickerMargin = `${this.tickerInitialMargin}px`;
    }
  }

  private maintainNextAndPrevArrows() {
    const positionX = this.snapContainer?.nativeElement?.getBoundingClientRect()?.x;

    this.hasNext = (positionX != (this.snapContainer?.nativeElement?.scrollWidth - this.snapContainer?.nativeElement?.offsetWidth) * -1);
    this.hasPrev = positionX != 0;


    let currentIndex = 0;
    const tickerWidth = parseInt(this.tickerWidth.replace('px', '')) + parseInt(this.tickerMargin.replace('px', ''));

    if (positionX == 0) {
      currentIndex = 1;
    } else {
      currentIndex = (positionX * -1 / (tickerWidth)) + 1;
    }

    this.currentIndex = Math.floor(currentIndex);
  }
}
