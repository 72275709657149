<section id="contact">
    <div class="container">

        <div class="meet-us contact-layout">
            <!-- <div class="panel">
                <h2 [innerHtml]="content.locationDetails.title"></h2>
                <div class="address-panel">
                    <div class="address">
                        <h5>
                            <span [innerHtml]="content.locationDetails.addressDetails"></span>
                            <img src="../../../../assets/us-flag.png" alt="">
                        </h5>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="lets-talk contact-layout">
            <div class="panel">
                <h2 class="talk" [innerHtml]="content.contactDetails.formOne.title"></h2>

                <div>
                    <p>{{ content.contactDetails.formOne.description }}</p>
                    <a [href]="'mailto:' + content.contactDetails.formOne.mailTo.link">
                        {{ content.contactDetails.formOne.mailTo.label }}
                    </a>
                    <a [href]="'tel:' + content.contactDetails.formOne.tel.link">
                        {{ content.contactDetails.formOne.tel.label }}
                    </a>
                </div>
            </div>
        </div>

    </div>
</section>