import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-input-with-label',
  templateUrl: './fs-input-with-label.component.html',
  styleUrls: ['./fs-input-with-label.component.scss']
})
export class FsInputWithLabelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() _value = '';
  @Input('placeHolderOne') placeHolderOne = '';
  @Input('placeHolderTwo') placeHolderTwo = '';
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

  _focusIn = this._value.length > 0;

  focusIn() {
    this._focusIn = true;
  }

  focusOut() {
    this._focusIn = this._value.length > 0 || +this.value > 0;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
    this.onChange.emit(val);
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this._focusIn = this._value.length > 0 || +this.value > 0;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
}
