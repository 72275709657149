import { Component, OnInit } from '@angular/core';
import { FadeService } from 'src/app/shared/services/fade.service';

@Component({
  selector: 'app-fade',
  templateUrl: './fade.component.html',
  styleUrls: ['./fade.component.scss']
})
export class FadeComponent implements OnInit {

  isShown: boolean;

  constructor(
    private fadeService: FadeService
  ) {

  }

  fadeClick() {
    this.fadeService.fadeClick();
  }

  ngOnInit(): void {
    this.fadeService.onShow.subscribe(() => { this.isShown = true });
    this.fadeService.onHide.subscribe(() => { this.isShown = false });
  }
}
