import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { handleError } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private baseUrl = `${environment.landingApiUrl}/s`;

  constructor(
    private http: HttpClient
  ) { }

  renew(uid): Observable<string> {
    const url = `${this.baseUrl}/r`;
    return this.http.post(url, { uid })
      .pipe(
        map((resp: any) => { return resp.uid; }),
        catchError(handleError)
      );
  }
}
