import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-fs-circle-range',
  templateUrl: './fs-circle-range.component.html',
  styleUrls: ['./fs-circle-range.component.scss']
})
export class FsCircleRangeComponent implements OnInit {

  @ViewChild('circleOuter') circleOuter: ElementRef;
  center = null;
  rotateValue: string = '';
  thumbLinesRotate: string = '';
  animating: boolean;
  animatingThumb: boolean;
  isDragging = false;
  innerValue: number;

  private animationSpeed = 700;

  @HostListener('document:mousemove', ['$event']) onMouseMove(e) { this.mouseMoveDrag(e); }
  @HostListener('document:mouseup', ['$event']) onMouseUp(e) { this.mouseMoveUp(e); }
  @HostListener('window:scroll', ['$event']) onScroll(event) {
    if (window.scrollY == 0) { this.initCenter(); }
  }

  @Input('selectedValues') selectedValues: string[] = ['Features', 'Budget'];
  @Input() disabled: boolean;
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter();

  private valueMapper = [
    { key: 29, values: ['Budget', 'Speed'] },
    { key: 151, values: ['Speed', 'Features'] },
    { key: 270, values: ['Features', 'Budget'] }
  ];

  previousDeg = 0;
  currentDeg = 0;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.initCenter();
      let selectedValue = 29;

      this.valueMapper.forEach(x => {
        let x1 = x.values;
        let x2 = this.selectedValues;

        if (x1.indexOf(x2[0]) != -1 && x1.indexOf(x2[1]) != -1 && x2.indexOf(x1[0]) != -1 && x2.indexOf(x1[1]) != -1) {
          selectedValue = x.key;
        }
      });

      this.hitDrag(selectedValue);
      this.emitValue(selectedValue);
    }, 10);
  }

  initCenter() {
    let rect = this.circleOuter.nativeElement.getBoundingClientRect();
    this.center = {
      x: rect.left + (rect.width / 2),
      y: rect.top + (rect.height / 2)
    };
  }

  circleMouseDown(event) {
    if (event.target == this.circleOuter.nativeElement) {
      // let currentDeg = this.rotate(event.pageX, event.pageY);
      let currentDeg = this.rotate(event.x, event.y);
      this.hitMove2(currentDeg);
    }
  }

  rotate = function (x, y) {
    let deltaX = x - this.center.x;
    let deltaY = y - this.center.y;

    let angle = Math.atan2(deltaY, deltaX) * 180 / Math.PI

    if (angle < 0) {
      angle = 180 + (180 + angle);
    }
    return angle
  };

  startDragging() {
    this.isDragging = true;
  }

  mouseMoveDrag(event) {
    if (this.isDragging == true) {
      let deg = this.rotate(event.pageX, event.pageY);
      this.hitDrag(deg);
    }
  }

  mouseMoveUp(event) {
    if (this.isDragging) {
      this.isDragging = false;
      let deg = this.rotate(event.pageX, event.pageY);
      this.hitMove2(deg);
    }
  }

  private calculateShortestPath(pointOne, pointTwo) {
    let pathOne = Math.max(pointOne, pointTwo) - Math.min(pointOne, pointTwo);
    let pathTwo = (360 - Math.max(pointOne, pointTwo) + Math.min(pointOne, pointTwo));
    return Math.min(pathOne, pathTwo);
  }

  private hitMove2(moveDeg) {
    let adjustDelay = moveDeg == this.previousDeg ? 0 : this.animationSpeed;

    let shortestPath = this.calculateShortestPath(this.previousDeg, moveDeg);

    if (this.previousDeg > moveDeg) {
      if (this.previousDeg - moveDeg > 180) {
        moveDeg = this.previousDeg + shortestPath;
      } else {
        moveDeg = this.previousDeg - shortestPath;
      }
    } else {
      if (moveDeg - this.previousDeg > 180) {
        moveDeg = this.previousDeg - shortestPath;
      } else {
        moveDeg = this.previousDeg + shortestPath;
      }
    }

    this.animating = true;
    this.rotateValue = `rotate(${moveDeg}deg)`;
    this.previousDeg = moveDeg;
    this.dragThumb(moveDeg, true);

    setTimeout(() => { this.hitMove3(moveDeg); }, adjustDelay);
  }

  private hitMove3(moveDeg) {
    let shortestPathsForValues = [];
    shortestPathsForValues.push({ key: 29, value: this.calculateShortestPath(29, moveDeg) });
    shortestPathsForValues.push({ key: 151, value: this.calculateShortestPath(151, moveDeg) });
    shortestPathsForValues.push({ key: 270, value: this.calculateShortestPath(270, moveDeg) });

    let shortestPathValue = Math.min.apply(Math, shortestPathsForValues.map(x => x.value));
    let shortestPathObject = shortestPathsForValues.filter(x => x.value == shortestPathValue)[0];

    moveDeg = shortestPathObject.key;

    this.dragThumb(moveDeg, true);

    let shortestPath = this.calculateShortestPath(this.previousDeg, moveDeg);

    if (this.previousDeg > moveDeg) {
      if (this.previousDeg - moveDeg > 180) {
        moveDeg = this.previousDeg + shortestPath;
      } else {
        moveDeg = this.previousDeg - shortestPath;
      }
    } else {
      if (moveDeg - this.previousDeg > 180) {
        moveDeg = this.previousDeg - shortestPath;
      } else {
        moveDeg = this.previousDeg + shortestPath;
      }
    }

    this.animating = true;
    this.rotateValue = `rotate(${moveDeg}deg)`;

    setTimeout(() => { this.animationEnd(moveDeg); }, this.animationSpeed);
  }

  private alignDegByDirection(moveDeg) {
    if (moveDeg > 360) { moveDeg = moveDeg - 360; }
    if (moveDeg < 0) { moveDeg = 360 + moveDeg; }

    this.animating = false;
    this.rotateValue = `rotate(${moveDeg}deg)`;
    // this.thumbLinesRotate = `rotate(-${moveDeg}deg)`;
    this.previousDeg = moveDeg;

    this.emitValue(moveDeg);
  }

  private animationEnd(moveDeg) {
    this.alignDegByDirection(moveDeg);
  }

  private hitDrag(dragDeg) {
    this.animating = false;
    this.rotateValue = `rotate(${dragDeg}deg)`;
    this.dragThumb(dragDeg, false);
    this.previousDeg = dragDeg;
  }

  private emitValue(value) {
    this.innerValue = value;
    this.onChange.emit(this.valueMapper.filter(x => x.key == value)[0].values);
  }

  private dragThumb(moveDeg, withAnimation) {
    if (this.previousDeg == 29 && moveDeg == 270) {
      this.animatingThumb = true;
      this.thumbLinesRotate = `rotate(90deg)`;
      setTimeout(() => {
        this.animatingThumb = false;
        this.thumbLinesRotate = `rotate(-270deg)`;
      }, this.animationSpeed);
    } else if (this.previousDeg == 270 && moveDeg == 29) {
      this.animatingThumb = true;
      this.thumbLinesRotate = `rotate(-390deg)`;
      setTimeout(() => {
        this.animatingThumb = false;
        this.thumbLinesRotate = `rotate(-29deg)`;
      }, this.animationSpeed);
    }
    else {
      this.animatingThumb = withAnimation;
      this.thumbLinesRotate = `rotate(-${moveDeg}deg)`;
    }
  }
}
