<section>
    <div class="container heading">
        <h4 class="uppercase">{{ content.title }}</h4>
        <a [routerLink]="['/blog']">See More</a>
    </div>
    <div class="container">
        <div class="latest-projects">
            <div class="project" *ngFor="let project of latestProjects">
                <a class="project-media" [href]="project.btn.link" target="_blank">
                    <img [src]="project.img">
                    <div class="fade"></div>
                    <a class="view-details">
                        {{ project.btn.label }}
                        <img src="../../../../assets/work-view-details.svg" alt="">
                    </a>
                </a>
                <div class="project-details">
                    <label>{{ project.createDate }} {{ project.location }}</label>
                    <p>{{ project.title }}</p> 
                </div>
            </div>
        </div>
    </div>
</section>