import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-responsive-dropdown-content',
  templateUrl: './fs-responsive-dropdown-content.component.html',
  styleUrls: ['./fs-responsive-dropdown-content.component.scss']
})
export class FsResponsiveDropdownContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
