import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss', '../../shared/styles/styles.scss']
})
export class WorkComponent implements OnInit {

  workList = [
    { imageUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1654229866/work/_manual/wepitch_hspe3w.png', title: 'WePitch', description: 'A livestreaming platform exclusively for entrepreneurs and investors', type: 'Video Streaming' },
    { imageUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1654229943/work/_manual/cloudstudio_t95ncy.png', title: 'Cloud Studio', description: 'A livestreaming platform exclusively for entrepreneurs and investors', type: 'Video Streaming' },
    { imageUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1654230003/work/_manual/hex_tsptai.png', title: 'Hex Social', description: 'A livestreaming platform exclusively for entrepreneurs and investors', type: 'Video Streaming' },
    { imageUrl: 'https://res.cloudinary.com/faststartup/image/upload/v1654230109/work/_manual/dola-health_betw9k.png', title: 'Dola Health', description: 'A livestreaming platform exclusively for entrepreneurs and investors', type: 'Video Streaming' },
  ]

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigateTo() {
    this.router.navigate(['/work']);
  }
}
