export function createPageContent() {
    return {
        sectionTitle: `Let's <br> build`,
        description: `Join Our <span>FastStartup Client Portal</span> <br> and let's get things moving`,
        btnBuild: {
            label: 'Build Now',
            img: '../../../../assets/btn-build-car.svg',
            link: ['/build-now']
        },
        buildAction: {
            label: 'Learn more about our services',
            link: ['/services']
        }
    }
}