<section class="timeline-header" [ngClass]="{'price-estimator' : locData.isPricingEstimator}" *ngIf="!locData.isPricingEstimator">
    <div class="development-speed">
        <div class="content">
            <h5>
                {{ locData.developmentSpeedTitle }}
                <a class="info">
                    <span>?</span>
                </a>
            </h5>
            <div class="development-speed-info">
                <app-fs-c-range [ranges]="locData.developmentSpeedValues" [value]="locData.developmentSpeedValue"
                    (onChange)="developmentSpeedChange($event)">
                </app-fs-c-range>
            </div>
        </div>
    </div>

    <div class="services-included">
        <div class="content" [ngClass]="{'left-aligned' : locData.isPricingEstimator}">
            <h5>
                {{ locData.servicesIncludedTitle }}
                <a class="info">
                    <span>?</span>
                </a>
            </h5>

            <div class="service-checkbox-items" *ngIf="locData.isPricingEstimator">
                <label class="fs-c-check" *ngFor="let item of locData.servicesIncludedItems"
                    [ngClass]="{'disabled':!item.enabled}">
                    <input type="checkbox" [checked]="item.checked" [disabled]="!item.enabled"
                        (change)="serviceChange(item, $event)">
                    <span class="label">{{ item.value }}</span>
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="service-items" *ngIf="!locData.isPricingEstimator">
                <b *ngFor="let item of locData.servicesIncludedItems">
                    <h5>{{ item.value }}</h5>
                </b>
            </div>
        </div>
    </div>

    <div class="no-of-sprints">
        <div class="content">
            <h5>
                {{ locData.numberOfSprintsTitle }}
                <a class="info">
                    <span>?</span>
                </a>
            </h5>
            <div class="num-of-sprints">
                {{ locData.sprintsCount }}
            </div>
        </div>
    </div>

    <div class="estimated-price">
        <div class="content">
            <h5> {{ locData.estimatedPriceLabel }} </h5>
            <div class="price-label">
                <b>
                    <h1>$ {{ (locData.estimatedPrice | number:'1.0') }}</h1>
                </b>
            </div>
            <b>
                <h5> {{ locData.estimatedPriceDetailsStartLabel }} {{ locData.sprintsCount }} {{
                    locData.estimatedPriceDetailsMidLabel }}{{ (locData.estimatedPrice / locData.sprintsCount |
                    number:
                    '1.0-0') }}</h5>
            </b>
        </div>
    </div>
</section>


<section class="sprint-timeline">
    <div class="sprint-timeline-matrix borders borders-{{ locData.sprintsCount }}">
        <div class="border" *ngFor="let sprint of [].constructor(locData.sprintsCount)"></div>
    </div>

    <div class="sprint-timeline-matrix sprint-{{ locData.sprintsCount }}">
        <div class="timeline timeline-{{ timeline.start }}-{{ timeline.end }} order-{{ i + 1 }}"
            *ngFor="let timeline of locData.timelines; index as i">{{ timeline.name }}</div>
    </div>

    <div class="sprint-timeline-matrix sprint-{{ locData.sprintsCount }} headers" *ngIf="locData.sprintsCount">
        <div *ngFor="let sprint of [].constructor(locData.sprintsCount); index as i">Sprint {{ i + 1 }}</div>
    </div>
</section>

<div class="services-included-title" *ngIf="locData.servicesIncludedData && locData.servicesIncludedData.length > 0">
    <h4>Services Included</h4>
</div>

<div class="sprint-services-details" *ngIf="locData.servicesIncludedData && locData.servicesIncludedData.length > 0">
    <div class="sprint-service" [ngClass]="{'active' : item.included }"
        *ngFor="let item of locData.servicesIncludedData; index as i">
        <h5 class="heading">{{ item.name }}</h5>
        <div *ngFor="let service of item.subServices">
            <b>
                <h5>
                    <i></i>
                    {{ service }}
                    <!-- <div class="service-info">?</div> -->
                </h5>
            </b>
        </div>
    </div>
</div>