import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { createPageContent } from 'src/content/main.content';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  modalIsScrolled: boolean;
  mobileMenuIsVisible: boolean = false;
  content: any;
  buildContainerWidth = null;

  @HostListener('window:resize', ['$event']) onResize(event) { this.maintainMobileMenuScroll(); this.maintainBuildContainerWidth(); }
  @ViewChild('menuContainer') menuContainer: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.content = createPageContent();
    this.maintainMobileMenuScroll();
    this.maintainBuildContainerWidth();
  }

  toggleMenu() {
    this.mobileMenuIsVisible = !this.mobileMenuIsVisible;
    if (this.mobileMenuIsVisible) {
      document.getElementById('fs-body').classList.add('fixed');
      this.maintainMobileMenuScroll();
    } else {
      document.getElementById('fs-body').classList.remove('fixed');
    }
  }

  openIntercom() {
    this.buildNow();
  }

  buildNow() {
    // window.location.href = 'http://portal.faststartup.io/login?step=signUp';
    const w = window as any;
    const message = `Hello,
I am interested in hiring FastStartup for my project. Are you available for a quick chat?`;
    w.Intercom('showNewMessage', message);
  }

  private maintainMobileMenuScroll() {
    setTimeout(() => { this.modalIsScrolled = (this.menuContainer?.nativeElement.offsetHeight + 100) > window.innerHeight; }, 10);
  }

  private maintainBuildContainerWidth() {
    this.buildContainerWidth = Math.max(148, ((window.innerWidth - 1280) / 2));
  }
}
