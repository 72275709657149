import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { from } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { ITimelineData } from '../../models/timeline-data.model';

@Component({
  selector: 'app-fs-timeline',
  templateUrl: './fs-timeline.component.html',
  styleUrls: ['./fs-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FsTimelineComponent implements OnInit, OnChanges {

  @Input('data') data: ITimelineData;
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter();

  showServiceDetails: boolean = false;
  visible: boolean;

  constructor() { }

  locData: ITimelineData;

  ngOnInit(): void { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes.data.currentValue) != JSON.stringify(changes.data.previousValue)) { 
      this.locData = this.data;
    }
  }

  hoverOn(items, item) {
    items.forEach(element => { element.isActive = false; });
    item.isActive = true;
  }

  hoverOff(items) {
    items.forEach(element => { element.isActive = null; });
  }

  developmentSpeedChange(e) {
    this.data.developmentSpeedValue = e;
    this.emitChanges();
  }

  serviceChange(item, e) {
    item.checked = e.target.checked;
    this.emitChanges();
  }

  showTooltip() {
    this.visible = true;
  }

  closeTooltip() {
    from([1]).pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.visible = false;
    })
  }

  tooltipClose() {
    this.visible = false;
  }

  private emitChanges() {
    this.onChange.emit({
      developmentSpeedValue: this.data.developmentSpeedValue,
      servicesIncludedItems: this.data.servicesIncludedItems
    });
  }
}
