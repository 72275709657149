import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FsSectionComponent } from './components/fs-section/fs-section.component';
import { FsRangeComponent } from './components/fs-range/fs-range.component';
import { FsFaqComponent } from './components/fs-faq/fs-faq.component';
import { FsTickerComponent } from './components/fs-ticker/fs-ticker.component';
import { FsDashedHeaderComponent } from './components/fs-dashed-header/fs-dashed-header.component';
import { FsDropdownMenuComponent } from './components/fs-dropdown-menu/fs-dropdown-menu.component';
import { FsTimelineComponent } from './components/fs-timeline/fs-timeline.component';
import { FsInputWithLabelComponent } from './components/fs-input-with-label/fs-input-with-label.component';
import { FsTooltipComponent } from './components/fs-tooltip/fs-tooltip.component';
import { FsResponsiveDropdownContentComponent } from './components/fs-responsive-dropdown-content/fs-responsive-dropdown-content.component';
import { FsInputComponent } from './components/fs-input/fs-input.component';
import { FsCRangeComponent } from './components/fs-c-range/fs-c-range.component';
import { FsCircularRangeComponent } from './components/fs-circular-range/fs-circular-range.component';
import { FsSkewButtonComponent } from './components/fs-skew-button/fs-skew-button.component';
import { FsCircleRangeComponent } from './components/fs-circle-range/fs-circle-range.component';
import { FsCircularBuildButtonComponent } from './components/fs-circular-build-button/fs-circular-build-button.component';
import { FsLoaderComponent } from './components/fs-loader/fs-loader.component';
import { FsFileUploadComponent } from './components/fs-file-upload/fs-file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FsBuildComponent } from './components/fs-build/fs-build.component';
import { FsContactComponent } from './components/fs-contact/fs-contact.component';
import { FsTickerRowComponent } from './components/fs-ticker-row/fs-ticker-row.component';
import { QuestionmarkPopupComponent } from './components/questionmark-popup/questionmark-popup.component';
import { SwiperModule } from "swiper/angular";
import { FsIncludedServicesComponent } from './components/fs-included-services/fs-included-services.component';
import { FsProjectTimelineComponent } from './components/fs-project-timeline/fs-project-timeline.component';
import { FsSelectPriorityComponent } from './components/fs-select-priority/fs-select-priority.component';
import { FsSelectServicesComponent } from './components/fs-select-services/fs-select-services.component';
import { FsEstimatedBudgetComponent } from './components/fs-estimated-budget/fs-estimated-budget.component';
import { FsTimelineFullscreenModalComponent } from './components/fs-timeline-fullscreen-modal/fs-timeline-fullscreen-modal.component';
import { SelectComponent } from './components/select/select.component';
import { FsWorkItemComponent } from './components/fs-work-item/fs-work-item.component';

@NgModule({
  declarations: [
    FsSectionComponent,
    FsRangeComponent,
    FsFaqComponent,
    FsTickerComponent,
    FsDashedHeaderComponent,
    FsDropdownMenuComponent,
    FsTimelineComponent,
    FsInputWithLabelComponent,
    FsTooltipComponent,
    FsResponsiveDropdownContentComponent,
    FsInputComponent,
    FsCRangeComponent,
    FsCircularRangeComponent,
    FsSkewButtonComponent,
    FsCircleRangeComponent,
    FsCircularBuildButtonComponent,
    FsLoaderComponent,
    FsFileUploadComponent,
    FsBuildComponent,
    FsContactComponent,
    FsTickerRowComponent,
    QuestionmarkPopupComponent,
    FsIncludedServicesComponent,
    FsProjectTimelineComponent,
    FsSelectPriorityComponent,
    FsSelectServicesComponent,
    FsEstimatedBudgetComponent,
    FsTimelineFullscreenModalComponent,
    SelectComponent,
    FsWorkItemComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxFileDropModule
  ],
  providers: [
    DecimalPipe
  ],
  exports: [
    ReactiveFormsModule,
    FsSectionComponent,
    FsRangeComponent,
    FsFaqComponent,
    FsTickerComponent,
    FsDashedHeaderComponent,
    FsDropdownMenuComponent,
    FsInputWithLabelComponent,
    FsTimelineComponent,
    FsTooltipComponent,
    FsInputComponent,
    FsCRangeComponent,
    FsCircularRangeComponent,
    FsSkewButtonComponent,
    FsCircleRangeComponent,
    FsCircularBuildButtonComponent,
    FsLoaderComponent,
    FsFileUploadComponent,
    FsBuildComponent,
    FsContactComponent,
    FsIncludedServicesComponent,
    FsProjectTimelineComponent,
    FsSelectPriorityComponent,
    FsSelectServicesComponent,
    FsEstimatedBudgetComponent,
    QuestionmarkPopupComponent,
    FsWorkItemComponent,
    SelectComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DecimalPipe,
    SwiperModule
  ]
})
export class SharedModule { }
