import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './app-main/pages/main-page/main-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { IntroductionComponent } from './app-main/components/introduction/introduction.component';
import { QualityComponent } from './app-main/components/quality/quality.component';

import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { BlogsComponent } from './app-main/components/blogs/blogs.component';
import { BlogComponent } from './app-main/components/blog/blog.component';
import { TrustedByComponent } from './app-main/components/trusted-by/trusted-by.component';
import { LatestProjectsComponent } from './app-main/components/latest-projects/latest-projects.component';
import { TestimonialsComponent } from './app-main/components/testimonials/testimonials.component';
import { ServicesComponent } from './app-main/components/services/services.component';
import { TeamComponent } from './app-main/components/team/team.component';
import { WorkComponent } from './app-main/components/work/work.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    IntroductionComponent,
    QualityComponent,
    BlogsComponent,
    BlogComponent,
    TrustedByComponent,
    LatestProjectsComponent,
    TestimonialsComponent,
    ServicesComponent,
    TeamComponent,
    WorkComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 60]);
    router.events.pipe(filter(e => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.anchor) { setTimeout(() => { viewportScroller.scrollToAnchor(e.anchor); }); }
        else if (e.position) { viewportScroller.scrollToPosition(e.position); }
        else { viewportScroller.scrollToPosition([0, 0]); }
      });
  }
}
